.edit-photo-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    padding-top: 20vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-white);

    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.4);
        width: 100vw;
    }

    .home-screen-img {
        width: 82vw;
        margin-top: 1vw;
    }

    .start-button {
        width: 50vw;
        height: 9vw;
        background-color: var(--color-secondary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 3px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
        position: fixed;
        bottom: 15vw;
    }

    .start-button-text {
        color: var(--color-primary);
        font-family: var(--font-family-primary);
        font-size: 4vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }

    .options-row {
        display: flex;
        flex-direction: row;
        width: 75vw;
        padding: 0vw 0vw;
        justify-content: space-between;
        margin-top: 0vw;
        text-transform: uppercase;
        box-sizing: border-box;

        .menu-icon {
            padding-right: 7px;
            display: none;
        }

        .icon {
            fill: var(--color-primary);
            stroke: var(--color-primary);
        }

        .menu-icon-selected {
            fill: var(--color-tertiary);
            stroke: var(--color-tertiary);
        }

        .option-menu-item {
            display: flex;
            padding-bottom: 1.5vw;
            color: var(--color-primary);
            font-family: var(--font-family-primary);
            font-size: 3.75vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            border-bottom: 2px solid transparent;
            transition: ease-in-out 0.5s;
        }

        .option-menu-item-contrast {
            color: var(--color-secondary);
        }

        #option-selected {
            border-bottom: 2px solid var(--color-tertiary);
            color: var(--color-tertiary);
            transition: ease-in-out 0.5s;
        }

        .option-selected {
            border-bottom: 2px solid var(--color-tertiary);
            color: var(--color-tertiary);
            transition: ease-in-out 0.5s;
        }

        .option-disabled {
            opacity: 0.5;
        }


    }

    .botom-menu {
        margin: 6.3vw 0 3vw 0;
    }

    .tools-container {
        height: 20vw;
    }

    .instructions-container {
        display: flex;
        flex-direction: column;
        padding-top: 2vw;

        .instructions {
            text-align: center;
            color: black;
            font-size: 4.2vw;
            line-height: 1.3;
            padding: 0px;
            margin: 0px;
            font-family: var(--font-family-primary);
            font-weight: 500;
        }
    }

    .remove-watermark-button {
        width: 69.33vw;
        height: 12vw;
        background-color: var(--color-tertiary);
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        // border-radius: 5.86vw;
        margin-top: 6vw;
        text-decoration: none;
        bottom: 15vw;
        color: var(--color-secondary);
        font-family: var(--font-family-secondary);
        font-size: 4.28vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;

        .without-watermark-text {
            color: var(--color-secondary);
            font-family: var(--font-family-secondary);
            font-size: 3.59vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            margin: 0px;
            padding: 0px;
        }
    }

    .remove-watermark-button:active {
        background-color: #990000;
        color: #ffffff;
    }

    .scroll-container {
        width: 90vw;
        display: flex;
        padding-top: 2vw;

        .thumbnail-container {
            width: 17vw;
            height: 17vw;
            margin-left: 1vw;

            .thumbnail-img-container {
                display: flex;
                width: 17vw;
                height: 17vw;
                border-radius: 1vw;
                overflow: hidden;
                object-fit: cover;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                border: solid 1vw var(--color-tertiary);
            }

            .thumbnail {
                width: 17vw;
                height: 17vw;
                border-radius: 1vw;
                object-fit: cover;
                box-sizing: border-box;
            }


            .selected-thumbnail {
                // transform: scale(1.1);
            }

            .selected {
                width: 17.5vw;
                height: 17.5vw;
                border: solid 1vw var(--color-tertiary);
                border-radius: 5px;
                box-sizing: border-box;
                margin-top: -7vw;
                background-color: var(--color-tertiary);
            }

            .check {
                position: relative;
                top: -1.3vw;
                margin-top: 1.5vw;
                margin-left: 12.8vw;
                margin-bottom: 0vw;
                width: 4vw;
                height: 4vw;
                z-index: 100;
                stroke: var(--color-tertiary);
                fill: var(--color-tertiary);
            }
        }
    }


    .save-container {
        width: 33.33vw;
        height: 10.4vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 15vw;

        .loading-spinner {
            width: 13.3vw;
            height: 13.3vw;
            object-fit: cover;
        }

        .save {
            width: 35vw;
            height: 10vw;
            background-color: var(--color-tertiary);
            border-radius: 2px;
            right: 4vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;

            .text-save {
                font-size: 5.3vw;
                font-weight: 700;
                font-family: var(--font-family-button);
                color: var(--color-primary);
            }
        }

        .save:active {
            background-color: var(--color-tertiary);
            color: #ffffff;
        }

        .contrast-save {
            background-color: var(--color-tertiary);

            .contrast-text-save {
                font-family: var(--font-family-primary);
                color: var(--color-secondary);
            }
        }
    }



    .centered {
        margin-left: auto;
        margin-right: auto;
        width: inherit;
    }

    .custom-caption-container {
        width: 85vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 6vw;

        .input-container {
            width: 5.3vw;
            height: 5.3vw;

            input[type="checkbox"] {
                appearance: none;
                margin: 0;
                font: inherit;
                color: currentColor;
                width: 5.3vw;
                height: 5.3vw;
                border: 2px solid var(--color-secondary);
                border-radius: 1.3vw;
                transform: translateY(-0.075em);

                display: flex;
                place-content: center;
            }

            input[type="checkbox"]:checked {
                background-color: var(--color-secondary);
            }

            input[type="checkbox"]::before {
                content: "";
                width: 3.3vw;
                height: 3.3vw;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--color-primary);
                background-color: var(--color-primary);
                transform-origin: bottom left;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 6%, 80% 0%, 43% 72%);
                // clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1) translateY(0.5vw);
            }
        }

        .custom-caption-label {
            font-family: var(--font-family-primary);
            padding-left: 2vw;
            color: var(--color-primary);
            font-size: 3.73vw;
            line-height: 4.8vw;
            text-transform: capitalize;

            .opacity-text {
                opacity: 0.5;
            }
        }

        .custom-caption-label-contrast {
            color: var(--color-secondary)
        }


    }

    .photomontage-row {
        width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 0vw;
        padding-bottom: 30vw;
        flex: 1;
        box-sizing: border-box;
        align-items: center;

        .montage-container {
            width: 45vw;
            height: 80vw;
            overflow: hidden;
            border-radius: 2.9vw;
        }

        .vertical-menu-container {
            width: 45vw;
            height: 80vw;
            display: flex;
            justify-content: flex-end;
            // background-color: aquamarine;

            .vertical-menu-content-container {
                width: 40vw;
                height: 80vw;
                display: flex;
                justify-content: space-between;
                // background-color: beige;
                display: flex;
                flex-direction: column;
                padding: 1vw 0 1vw 0;
                box-sizing: border-box;

                .menu-block {
                    width: inherit;
                    height: 34vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .menu-title {
                        font-family: var(--font-family-primary);
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 3.73vw;
                        color: var(--color-primary);
                    }


                    .scroll-container-16-by-9 {
                        width: 40vw;
                        height: 25vw;
                        display: flex;
                        padding-top: 2vw;
                        flex-wrap: wrap;

                        .thumbnail-container {
                            width: 12.0vw;
                            height: 12.0vw;
                            margin-left: 1vw;
                            margin-bottom: 1vw;
                            position: relative;

                            .thumbnail-img-container {
                                display: flex;
                                width: 12.0vw;
                                height: 12.0vw;
                                border: solid 0.5vw var(--color-secondary);
                            }

                            .thumbnail {
                                width: 12.0vw;
                                height: 12.0vw;
                            }

                            .dashed {
                                border: dashed 1px var(--color-secondary);
                                box-sizing: border-box;
                            }


                            .selected-thumbnail {
                                // transform: scale(1.1);
                            }

                            .selected {
                                width: 12.5vw;
                                height: 12.5vw;
                                border: solid 0.5vw var(--color-secondary);
                                border-radius: 3px;
                                box-sizing: border-box;
                                margin-top: 0vw;
                                background-color: var(--color-secondary);
                            }

                            .check {
                                position: absolute;
                                top: 0vw;
                                margin-top: 0vw;
                                margin-left: 9.5vw;
                                margin-bottom: 0vw;
                                width: 3vw;
                                height: 3vw;
                                stroke: var(--color-secondary);
                                fill: var(--color-secondary);
                            }
                        }
                    }
                }

            }
        }
    }

}

.loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-aspect-ratio: 9/15) {
    .edit-photo-container {
        padding-top: 20vw;

        .options-row {
            width: 85vw;
        }

        .tools-container {
            height: 27.5vw;
        }

        .scroll-container {
            width: 90vw;
            display: flex;
            padding-top: 3vw;

            .thumbnail-container {
                width: 20vw;
                height: 20vw;

                .thumbnail-img-container {
                    display: flex;
                    width: 20vw;
                    height: 20vw;
                    border-radius: 1vw;
                    overflow: hidden;
                    object-fit: cover;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                }

                .thumbnail {
                    width: 20vw;
                    height: 20vw;
                    // transform: scale(1.5);
                }


                .selected-thumbnail {
                    // transform: scale(1.1);
                }

                .selected {
                    width: 20.5vw;
                    height: 20.5vw;
                }

                .check {
                    margin-left: 16vw;
                }
            }
        }

        .photomontage-row {
            width: 90vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 10vw;

            .vertical-menu-container {
                width: 45vw;
                height: 80vw;
                display: flex;
                justify-content: flex-end;
                // background-color: aquamarine;

                .vertical-menu-content-container {
                    width: 40vw;
                    height: 80vw;
                    display: flex;
                    justify-content: space-between;
                    // background-color: beige;
                    display: flex;
                    flex-direction: column;
                    padding: 1vw 0 1vw 0;
                    box-sizing: border-box;

                    .menu-block {
                        width: inherit;
                        height: 34vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .menu-title {
                            font-family: var(--font-family-primary);
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 3.73vw;
                            color: var(--color-primary);
                        }
    
    
                        .scroll-container-16-by-9 {
                            width: 40vw;
                            height: 25vw;
                            display: flex;
                            padding-top: 2vw;
                            flex-wrap: wrap;
    
                            .thumbnail-container {
                                width: 12.0vw;
                                height: 12.0vw;
                                margin-left: 1vw;
                                margin-bottom: 1vw;
                                position: relative;
    
                                .thumbnail-img-container {
                                    display: flex;
                                    width: 12.0vw;
                                    height: 12.0vw;
                                    border: solid 0.5vw var(--color-secondary);
                                }
    
                                .thumbnail {
                                    width: 12.0vw;
                                    height: 12.0vw;
                                }
    
                                .dashed {
                                    border: dashed 1px var(--color-secondary);
                                    box-sizing: border-box;
                                }
    
    
                                .selected-thumbnail {
                                    // transform: scale(1.1);
                                }
    
                                .selected {
                                    width: 12.5vw;
                                    height: 12.5vw;
                                    border: solid 0.5vw var(--color-secondary);
                                    border-radius: 3px;
                                    box-sizing: border-box;
                                    margin-top: 0vw;
                                    background-color: var(--color-secondary);
                                }
    
                                .check {
                                    position: absolute;
                                    top: 0vw;
                                    margin-top: 0vw;
                                    margin-left: 9.5vw;
                                    margin-bottom: 0vw;
                                    width: 3vw;
                                    height: 3vw;
                                    stroke: var(--color-secondary);
                                    fill: var(--color-secondary);
                                }
                            }
                        }
                    }

                }
            }
        }


        .save-container {
            width: 40vw;
            height: 13.3vw;
            .save {

                .text-save {
                    font-size: 5.3vw;
                    font-weight: 700;
                }
            }
        }


    }
}

@media (min-aspect-ratio: 10/9) {
    .edit-photo-container {
        padding-top: 7vw;

        .scroll-container {
            position: absolute;
            top: 10vw;
            left: 5vw;
            width: 13vw;
            height: 35vw;
            display: flex;
            flex-direction: column;
            padding-top: 0vw;

            .thumbnail-container {
                width: 13vw;
                height: 13vw;
                margin-left: 0vw;
                margin-top: 0.5vw;

                .thumbnail-img-container {
                    display: flex;
                    width: 13vw;
                    height: 13vw;
                    border-radius: 1vw;
                    overflow: hidden;
                    object-fit: cover;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    border: solid 1vw var(--color-tertiary);
                }

                .thumbnail {
                    width: 13vw;
                    height: 13vw;
                    border-radius: 1vw;
                    object-fit: cover;
                    box-sizing: border-box;
                }


                .selected-thumbnail {
                    // transform: scale(1.1);
                }

                .selected {
                    width: 13vw;
                    height: 13vw;
                    border: solid 0.5vw var(--color-tertiary);
                    border-radius: 1vw;
                    box-sizing: border-box;
                    margin-top: -4.5vw;
                    background-color: var(--color-tertiary);
                }

                .check {
                    position: relative;
                    top: -1.5vw;
                    margin-top: 1.5vw;
                    margin-left: 10vw;
                    margin-bottom: 0vw;
                    width: 2.5vw;
                    height: 2.5vw;
                    z-index: 100;
                    stroke: var(--color-tertiary);
                    fill: var(--color-tertiary);
                }
            }
        }

        .frame-scroll-container {
            left: 75vw;
        }

        .option-menu-item {
            display: flex;
            padding-bottom: 0;
            color: var(--color-primary);
            font-family: var(--font-family-primary);
            font-size: 2vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            border: none;
            position: absolute;
            left: 5vw;
            top: 5vw;
            z-index: 500;
            width: 13vw;
            text-align: center;
        }

        .frames-option-menu-item {
            left: 75vw;
        }
    }
}