.html-for-pdf-container {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 24px;

    .html-for-pdf-bleed-container {
        box-sizing: border-box;
        position: relative;
        left: 0;
        padding: 12px;
        margin-bottom: 96px;

        .ticket-img {
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;
        }

        .mark {
            box-sizing: border-box;
            position: absolute;
            background-color: black;
        }

        .vertical {
            width: 1px;
            height: 9px;
        }

        .horizontal {
            width: 9px;
            height: 1px;
        }

        .top {
            top: 0;
        }

        .bottom {
            bottom: 0;
        }

        .left {
            left: 0;
        }

        .right {
            right: 0;
        }

        .vertical.top {
            transform: translate(0px, -21px);
        }

        .vertical.bottom {
            transform: translate(0px, 21px);
        }

        .horizontal.top.left, .horizontal.bottom.left {
            transform: translate(-21px, 0px);
        }

        .horizontal.top.right, .horizontal.bottom.right {
            transform: translate(21px, 0px);
        }

        .html-for-pdf-crop-container {
            box-sizing: border-box;
            position: relative;
        }
    }
}