.help-container {
    position: fixed;
    width: 100vw;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 13vw;
    padding-bottom: 12vw;
    align-items: center;

    .main-carousel-container {
        display: flex;
        width: 100vw;
        height: 95.2vw;
        overflow: hidden;
        justify-content: center;

        .carousel-container {
            width: 205.7vw;
            height: 67.2vw;


            .help-item-container {
                width: 68vw;
                height: 95.2vw;
                background-color: var(--color-primary);
                padding: 5.4vw 5.4vw;
                box-sizing: border-box;
                transition: 0.9s;

                .title-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 9.5vw;
                    width: 57;

                    .help-item-number-container {
                        width: 9.5vw;
                        height: 9.5vw;
                        border-radius: 9.5vw;
                        border: .6vw solid var(--color-secondary);
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .help-item-number {
                            width: 7.48vw;
                            height: 7.48vw;
                            border-radius: 7.48vw;
                            background-color: var(--color-secondary);
                            color: var(--color-tertiary);
                            margin: 0px;
                            padding: 0px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            font-family: var(--font-family-primary);
                            font-size: 6.38vw;
                            font-weight: bold;
                            box-sizing: border-box;
                        }
                    }

                    .help-item-title {
                        height: 8vw;
                        width: 41.6vw;
                        padding: 0vw 3vw;
                        color: var(--color-secondary);
                        font-family: var(--font-family-primary);
                        font-size: 4.15vw;
                        font-weight: bold;
                    }
                }

                .help-img-container {
                    width: 57vw;
                    height: 74.37vw;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    box-sizing: border-box;

                    .help-img {
                        width: 54.4vw;
                    }

                    .midle-item {
                        padding-bottom: 4.25vw;
                    }
                }
            }

            .non-active-item {
                transform: scale(0.9);
                transition: 0.9s;
            }
        }
    }

    .dot-container {
        display: flex;
        width: 100vw;
        justify-content: center;

        .dot {
            width: 1.5vw;
            height: 1.5vw;
            border-radius: 1.5vw;
            border: 1px solid var(--color-tertiary);
            margin: 3vw 2vw;
        }

        .active-dot {
            background-color: var(--color-tertiary);
        }
    }

    .got-it-button {
        background-color: var(--color-primary);
        width: 53vw;
        height: 10vw;
        border-radius: 2px;
        font-family: var(--font-family-primary);
        color: var(--color-secondary);
        font-size: 5vw;
        margin-bottom: 26vw;
    }
}
@media (max-aspect-ratio: 18/30) {
    .help-container {
        padding-top: 18vw;
        padding-bottom: 20vw;

        .main-carousel-container {
            height: 112vw;

            .carousel-container {
                width: 242vw;
                height: 67.2vw;


                .help-item-container {
                    width: 80vw;
                    height: 112vw;
                    padding: 6.4vw 6.4vw;

                    .title-row {
                        height: 11.7vw;
                        width: 67.2;

                        .help-item-number-container {
                            width: 11.7vw;
                            height: 11.7vw;
                            border-radius: 11.7vw;
                            border: .7vw solid var(--color-secondary);

                            .help-item-number {
                                width: 8.8vw;
                                height: 8.8vw;
                                border-radius: 8.8vw;
                                font-size: 7.5vw;
                            }
                        }

                        .help-item-title {
                            height: 11.7vw;
                            width: 49vw;
                            padding: 0vw 3.5vw;
                            font-size: 4.9vw;
                        }
                    }

                    .help-img-container {
                        width: 67.2vw;
                        height: 87.5vw;

                        .help-img {
                            width: 64vw;
                        }

                        .midle-item {
                            padding-bottom: 5vw;
                        }
                    }
                }
            }
        }

        .dot-container {

            .dot {
                width: 1.5vw;
                height: 1.5vw;
                border-radius: 1.5vw;
                border: 1px solid var(--color-tertiary);
                margin: 4.5vw 2vw;
            }
        }

        .got-it-button {
            width: 68vw;
            height: 12.8vw;
            border-radius: 2px;
            font-size: 5.5vw;
            margin-bottom: 32vw;
        }
    }
}