@font-face {
    font-family: 'Mazin';
    src: local('Mazin'), url(../../fonts/mazin/Mazin-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Mazin';
    font-weight: 700;
    src: local('Mazin'), url(../../fonts/mazin/Mazin-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Mazin';
    font-weight: 900;
    src: local('Mazin'), url(../../fonts/mazin/Mazin-Black.ttf) format('truetype');
}
  