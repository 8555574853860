.select-favorite-photo-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-image: url('/assets/images/nasdaq-background.png');
  // background-size:     cover;                      /* <------ */
  // background-repeat: repeat;
  // background-position: center center; 
  padding-top: 26vw;
  box-sizing: border-box;
  height: 100vh;

  .select-favorite-photo-title {
    font-family: var(--font-family-header);
    width: 100%;
    text-align: center;
    font-size: 5.33vw;
    color: var(--color-secondary);
    font-weight: 900;
    padding: 6.93vw 0 6.93vw 0;
    margin: 0;
  }

  .select-favorite-photo-instrucctions {
    font-family: var(--font-family-primary);
    width: 100%;
    text-align: center;
    font-size: 3.73vw;
    color: #ffffff;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  .select-favorite-photo-scroll-content-container {
    position: absolute;
    margin: 0px 0px 0px 11vw;
    display: flex;
    flex-wrap: wrap;
    // height: 60vh;

    .select-photo-array-photo-container {
      width: 23.5vw;
      height: 23.5vw;
      margin-right: 2.5vw;
      margin-bottom: 2.5vw;
      display: flex;

      .select-photo-array-photo {
        width: 23.5vw;
        height: 23.5vw;
        object-fit: cover;
      }

      .selected-photo {
        width: 23.5vw;
        height: 23.5vw;
        position: absolute;
      }
    }


    .select-photo-array-photo-bigger-container {
      width: 35vw;
      height: 35vw;

      .select-photo-array-photo-bigger {
        width: 35vw;
        height: 35vw;
      }
    }



  }

  .select-favorite-view-all {
    width: 100%;
    font-family: var(--font-family-primary);
    text-align: center;
    font-size: 3.20vw;
    line-height: 3.84;
    text-decoration: underline;
    color: var(--color-tertiary);
    font-weight: 300;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 10vw;
  }

}

@media (min-aspect-ratio: 9/15) {}


@media (max-aspect-ratio: 9/15) {}