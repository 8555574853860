.welcome-user__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-bottom: 35vw;

  .welcome-user__title {
    font-family: var(--font-family-header);
    width: 85vw;
    text-align: left;
    font-size: 5.87vw;
    line-height: 6.67vw;
    color: var(--color-dark-gray);
    font-weight: 400;
    margin: 0;
    text-transform: none;
  }

  .welcome-user__form--container {
    display: flex;
    flex-direction: column;
    width: 96vw;
    height: 42.67vw;
    padding: 5.33vw;
    border-radius: 2.13vw;
    border: 1px solid var(--color-light-gray);
    box-sizing: border-box;
    background-color: var(--color-white);
    margin-top: 3vw;
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 4.8vw;
    line-height: 7.47vw;
    gap: 1vw;
    color: var(--color-dark-gray);

    .welcome-user__form--row {
      width: 100%;
      display: flex;
      flex-direction: row;

      .welcome-user__form--row-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin: 0 0 4vw 0;

        .welcome-user__form--data {
          font-weight: 400;
          font-size: 3.73vw;
          line-height: 4.53vw;
        }
      }
    }
    
  }

  .welcome-user__advice--container {
    width: 85.33vw;
    margin-top: auto;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 4.53vw;
  }
}