@font-face {
    font-family: 'Gotham';
    font-weight: 400;
    src: local('Gotham'), url(../../fonts/gotham/GothamLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 400;
    font-style: italic;
    src: local('Gotham'), url(../../fonts/gotham/GothamLightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 500;
    src: local('Gotham'), url(../../fonts/gotham/GothamMedium.ttf) format('truetype');
}


@font-face {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: italic;
    src: local('Gotham'), url(../../fonts/gotham/GothamMediumItalic.ttf) format('truetype');
}


@font-face {
    font-family: 'Gotham';
    font-weight: 700;
    src: local('Gotham'), url(../../fonts/gotham/GothamBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 700;
    font-style: italic;
    src: local('Gotham'), url(../../fonts/gotham/GothamBoldItalic.ttf) format('truetype');
}
  