.photo-detail-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-image: url('/assets/images/nasdaq-background.png');
  background-size: cover;
  /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 30vw;
  box-sizing: border-box;
  align-items: center;

  .disabled {
    opacity: 0.5;
  }

  .photo-detail-image-to-generate {
    position: fixed;
    left: 100%;
  }

  .photo-detail-title {
    font-family: var(--font-family-primary);
    width: 100%;
    text-align: center;
    font-size: 5.33vw;
    line-height: 6.66vw;
    color: #ffffff;
    font-weight: 400;
    padding: 6.93vw 0 6.93vw 0;
    margin: 0;
  }

  .photo-detail-image {
    width: 55vw;
    // height: 96vw;
    object-fit: cover;
    border: 0.25px solid var(--color-secondary);
    box-sizing: border-box;
    border-radius: 2.9vw;
  }

  .photo-detail-buttons-container {
    width: 100vw;
    height: 10.4vw;
    display: flex;
    position: fixed;
    bottom: 15vw;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw 0 5vw;
    box-sizing: border-box;

    .photo-detail-decline-button {
      min-width: 32.26vw;
      height: 10.4vw;
      border: 2px solid var(--color-tertiary);
      border-radius: 1vw;
      box-sizing: border-box;
      background-color: transparent;
      font-family: var(--font-family-button);
      font-weight: 700;
      font-size: 5vw;
      color: var(--color-secondary);
      padding: 0 2vw;
    }

    .photo-detail-agree-button {
      width: 52vw;
      height: 10.4vw;
      border: 2px solid var(--color-primary);
      box-sizing: border-box;
      border-radius: 1vw;
      background-color: var(--color-secondary);
      font-family: var(--font-family-button);
      font-weight: 700;
      font-size: 5vw;
      color: var(--color-primary);
      padding: 0;
    }

    .photo-detail-share-button {}

    .photo-detail-create-souvenir-button {}

  }

  .photo-detail-buttons-container-centered {
    justify-content: center;
  }

}

@media (min-aspect-ratio: 9/15) {
  .photo-detail-container {
    padding-top: 22vw;


    .photo-detail-title {}

    .photo-detail-image {
      width: 53vw;
    }

    .photo-detail-buttons-container {


      .photo-detail-decline-button {}

      .photo-detail-agree-button {}

    }

  }
}