.loading-bar-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    align-items: center;

    .loading-bar-content {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 66.5vh;

        .loading-screen-text {
            width: 100%;
            font-family: var(--font-family-primary);
            font-weight: 700;
            font-size: 3.5vw;
            color: #000000;
            text-align: center;
            margin-top: 0vw;
        }

        .loading-only-bar-container {
            display: flex;
            flex-direction: column;
            height: 15vw;
            align-items: center;
            margin-bottom: -3.2vw;

            .loading-bar {

                transform: scale(0.1, 1);
                transition: transform 0.3s ease;
                animation-name: loading-animation;
                animation-duration: 10s;
                animation-iteration-count: infinite;
    
                &:nth-child(2) { animation-delay: 0.25s; }
            }
            @keyframes loading-animation {
                0% { transform: scale(0.1, 1) }
                100% { transform: scale(2.7, 1) }
            }
        }

        // .loading-logo-container {
        //     display: flex;
        //     height: 15vw;
        //     align-items: center;
        //     margin-bottom: -15vw;

        //     transform: translate(0vw, 0vw);
        //     transition: transform 0.3s ease;
        //     animation-name: loading-animation-smile;
        //     animation-duration: 10s;
        //     animation-iteration-count: infinite;
                
        //         &:nth-child(2) { animation-delay: 0.25s; }

        //     .loading-logo {
        //         position: relative;
        //         width: 13.3vw;
        //     }

        //     @keyframes loading-animation-smile {
        //         0% { transform: translate(-10vw, 0vw) }
        //         100% { transform: translate(40vw, 0vw) }
        //     }
        // }
    }

    .loading-bar-content-cloud {
        display: flex;
        flex-direction: column;
        position: relative;
        top: unset;
        // transform: translate(0, 76%);
    }
}

.loading-bar-container-cloud {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 200;
    align-items: center;
    top:unset;
    bottom: unset;
    left: unset;
    right: unset;
}