.desktop-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    // background-color: var(--color-primary);

    .content-container {
        width: 73vw;
        padding: 5vw 5vw 5vw 5vw;
        display: flex;
        flex-direction: column;

        .qr {
            width: 18.18vw;
        }

        .qrCode-wrap {
            width: 18.18vw;
            height: 18.18vw;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;


    
            .qrCode {
                width: 16vw;
            }
        }

        .advice-text {
            font-family: var(--font-family-terciary);
            font-weight: 700;
            color: #ffffff;
            font-size: 3.35vw;
            letter-spacing: 1%;
            margin: 2vw 0;
            line-height: 3.6vw;
        }

        .second-line {
            font-family: var(--font-family-terciary);
            font-weight: 400;
            color: #ffffff;
            font-size: 2.1vw;
            letter-spacing: 1%;
            margin: 1vw 0;
            line-height: 1.8vw;
        }

        .logo-home {
            margin-top: auto;
            width: 15.15vw;
        }
    }

    .image-container {
        width: 27vw;
        height: 100vh;

        .hole-image {
            width: 27vw;
            height: 100vh;
            overflow: hidden;
            z-index: 100;
            object-fit: cover;
            box-sizing: border-box;
        }

        .rc-logo {
            width: 6.6vw;
            position: absolute;
            top: 4vw;
            right: 4vw;
        }
    }
}

.desktop-container::-webkit-scrollbar {
    display: none;
  }

.desktop-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}