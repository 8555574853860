.terms-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 26vw;
  box-sizing: border-box;
  flex: 1;
  display: flex;

  .terms-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .terms-title {
      font-family: var(--font-family-header);
      width: 70vw;
      text-align: center;
      font-size: 4.2vw;
      color: var(--color-secondary);
      font-weight: 900;
      padding: 5.6vw 0 5.6vw 0;
      margin: 0;
      text-transform: uppercase;
    }

    .terms-subtitle {}

    .terms-scroll-container-parent {
      .terms-scroll-container {
        width: 90vw;
        height: 40vh;
        position: absolute;
        top: 0vw;
        left: 0vw;
        background-color: #ffffff;
        border: 1px solid var(--color-quaternary);
        box-sizing: border-box;

        .terms-scroll-content-container {
          padding: 0vw 4vw 4vw 4vw;

          .terms-paragraph-title {
            font-family: var(--font-family-body);
            font-weight: 400;
            font-size: 4vw;
            color: var(--color-black);
            text-transform: capitalize;
          }

          .terms-paragraph {
            font-family: var(--font-family-body);
            font-weight: 400;
            font-size: 3.7vw;
            color: var(--color-black);
          }
        }
      }

    }

    .terms-buttons-container {
      width: 100vw;
      display: flex;
      // position: fixed;
      bottom: 15vw;
      align-items: center;
      justify-content: space-between;
      padding: 0 10vw 0 10vw;
      box-sizing: border-box;
      margin-top: auto;
      margin-bottom: auto;

      .terms-decline-button {
        width: 37vw;
        height: 10.4vw;
        border: 2px solid var(--color-tertiary);
        border-radius: 1vw;
        box-sizing: border-box;
        background-color: transparent;
        font-family: var(--font-family-button);
        font-weight: 700;
        font-size: 5.5vw;
        color: var(--color-secondary);
        padding: 0;
        text-transform: uppercase;
      }

      .terms-agree-button {
        width: 37vw;
        height: 10.4vw;
        box-sizing: border-box;
        background-color: var(--color-tertiary);
        font-family: var(--font-family-button);
        font-weight: 700;
        font-size: 5.5vw;
        color: var(--color-secondary);
        padding: 0;
        border: none;
        text-transform: uppercase;
      }

    }
  }
}