.booking-id__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .booking-id__title {
    font-family: var(--font-family-header);
    width: 85vw;
    text-align: left;
    font-size: 5.87vw;
    line-height: 6.67vw;
    color: var(--color-dark-grey);
    font-weight: 400;
    margin: 0;
    text-transform: none;
  }

  .booking-id__try-again {
    display: flex;
    font-family: var(--font-family-header);
    width: 85vw;
    height: 12vw;
    text-align: left;
    font-size: 4.27vw;
    line-height: 5.07vw;
    color: var(--color-dark-grey);
    font-weight: 400;
    text-transform: none;
    align-items: center;
  }

  .booking-id__form--container {
    display: flex;
    flex-direction: column;
    width: 85vw;
    margin-top: 3vw;
    font-family: var(--font-family-primary);
    font-weight: 600;
    font-size: 3.2vw;
    line-height: 3.84vw;
    text-transform: uppercase;
    gap: 1vw;

    .booking-id__input{
      width: 75vw;
      height: 12.80vw;
      border-radius: 1.6vw;
      border: none;
      padding: 0 5.6vw;
      font-weight: 400;
      font-size: 4vw;
      font-family: var(--font-family-primary);

      &::placeholder {
        color: #D9DFE3;
      }
    }

    .booking-id__input--invalid {
      border: 1px solid var(--color-quaternary);

    }

    .booking-id__input--invalid-label {
      font-size: 3.20vw;
      text-transform: none;
      color: var(--color-quaternary);
    }
  }

}