.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(22, 22, 22, 0.9);
    z-index: 202;
}

.modal-container {
    width: 88vw;
    min-height: 160vw;
    padding-bottom: 5vw;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: transparent;

    .close-modal-container {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        padding: 4vw 0vw;

        .close-modal-x {
            cursor: pointer;
        }
    }

    .modal-content {
        width: 88vw;
        min-height: 140vw;
        background: #ffffff;
        padding: 5vw 5vw 5vw 5vw;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;


        .register-title {
            font-family: Poppins;
            text-align: center;
            font-weight: 600;
            color: #000000;
            font-size: 4.27vw;
            padding-top: 2vw;
            margin: 0;
            letter-spacing: 1px;
        }

        .form-container {
            padding-top: 6vw;

            .input-container {
                width: 78vw;
                margin-top: 4.2vw;

                .input-label {
                    font-family: Poppins;
                    font-size: 2.8vw;
                    line-height: 3.2vw;
                    color: #000000;

                    .required {
                        color: red;
                    }
                }

                .input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                        
                    .input-firstname {
                        width: 71.5vw;
                        height: 11.2vw;
                        padding: 0 2.1vw;
                        box-sizing: border-box;
                        font-size: 3.2vw;
                        letter-spacing: 1px;
                        font-family: Poppins;
                        color: #000000;

                    }             
                }

            }
        }

        .consent-container {
            display: flex;
            flex-direction: row;
            padding: 3vw 0;
            width: 71.5vw;

            .consent-check-container {
                padding-right: 1.5vw;
            }

            .agree-text {
                font-family: Poppins;
                font-size: 2.1vw;
                margin: 0;
                padding: 0;
                text-transform: uppercase;

                .agree-text-link {
                    font-size: 2.2;
                    text-decoration: underline;
                    font-weight: 700;
                }
            }
        }

        .submit-button {
            width: 40vw;
            height: 13.3vw;
            background-color: #B0B7BC;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;

            .submit-button-text {
                font-family: Poppins;
                font-size: 3.8vw;
                margin: 0;
                padding: 0;
                color: #ffffff;
            }
        }

        .submit-button-available {
            background-color: var(--color-primary);
        }

    }

}