@font-face {
    font-family: 'Nunito-Sans';
    font-weight: 400;
    src: local('Nunito-Sans'), url(../../fonts/nunito-sans/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito-Sans';
    font-weight: 700;
    src: local('Nunito-Sans'), url(../../fonts/nunito-sans/NunitoSans-Bold.ttf) format('truetype');
}