.photo-montage-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 1vw;
    
    .background {
        background-size: 75vw 75vw;
        width: 75vw;
        height: 75vw;
        overflow: hidden;
        object-fit: cover;
        box-sizing: content-box;
        border: 1px solid #E3E3E3;

        .photo-to-pinch-container {
            mask-size: 75vw 75vw;
            --webkit-mask-size: 75vw 75vw;

            position: absolute;
            width: 75vw;
            height: 75vw;
            overflow: hidden;



            .photo-to-pinch {
                position: absolute;
                height: 75vw;
                overflow: hidden;
                object-fit: cover;
            }

            .adjust-container {
                width: 69vw;
                min-height: 13.3vw;
                background-color: #ffffff;
                opacity: 0.9;
                // margin-left: auto;
                // margin-right: auto;
                left: 3vw;
                bottom: 5vw;
                align-items: center;
                justify-content: center;
                display: flex;
                position: absolute;

                .adjust-text {
                    width: 77vw;
                    font-family: var(--font-family-secondary);
                    text-align: center;
                    font-size: 3.5vw;
                    color: #000000;
                }
            }

            .div-to-pinch {
                position: static;
                // display: flex;
                width: 75vw;
                height: 75vw;
                // overflow: hidden;
                z-index: 1000;
                // background-color: rgba(0,0,0,0.1);
            }
        }
        
        .photo-to-edit {
            position: absolute;
            width: 75vw;
            height: 75vw;
            overflow: hidden;
            object-fit: cover;
        }
    }

}

.photo-montage-container-16-by-9 {    
    position: absolute;
    width: 45vw;
    display: flex;
    justify-content: flex-start;
    
    .background {
        background-size: 45vw 80vw;
        width: 45vw;
        height: 80vw;
        overflow: hidden;
        object-fit: cover;
        box-sizing: content-box;
        border: 1px solid #E3E3E3;
        border-radius: 2.66vw;
        // outline: solid 1px #cccccc;
        // outline-offset: 2px;

        .photo-to-pinch-container {
            mask-size: 45vw 80vw;
            --webkit-mask-size: 45vw 80vw;

            position: absolute;
            width: 45vw;
            height: 80vw;
            overflow: hidden;
            border-radius: 2.66vw;



            .photo-to-pinch {
                position: absolute;
                height: 80vw;
                overflow: hidden;
                object-fit: cover;
            }

            .adjust-container {
                width: 40vw;
                min-height: 13.3vw;
                background-color: #ffffff;
                opacity: 0.9;
                // margin-left: auto;
                // margin-right: auto;
                left: 3vw;
                bottom: 5vw;
                align-items: center;
                justify-content: center;
                display: flex;
                position: absolute;

                .adjust-text {
                    width: 70vw;
                    font-family: var(--font-family-secondary);
                    text-align: center;
                    font-size: 3.5vw;
                    color: #000000;
                }
            }

            .div-to-pinch {
                position: static;
                // display: flex;
                width: 45vw;
                height: 80vw;
                // overflow: hidden;
                z-index: 1000;
                // background-color: rgba(0,0,0,0.1);
            }
        }
        
        .photo-to-edit {
            position: absolute;
            width: 45vw;
            height: 80vw;
            overflow: hidden;
            object-fit: cover;
        }
    }
}

@media (max-aspect-ratio: 9/15) {
    .photo-montage-container {
        padding-top: 3vw;

        .background {
            background-size: 85vw 85vw;
            width: 85vw;
            height: 85vw;
    
            .photo-to-pinch-container {
                mask-size: 85vw 85vw;
                --webkit-mask-size: 85vw 85vw;
    
                width: 85vw;
                height: 85vw;
    
                .div-to-pinch {
                    width: 85vw;
                    height: 85vw;
                }
    
                .photo-to-pinch {
                    height: 85vw;
                }

                .adjust-container {
                    width: 77vw;
                    min-height: 13.3vw;
                    background-color: #ffffff;
                    opacity: 0.9;
                    // margin-left: auto;
                    // margin-right: auto;
                    left: 4.8vw;
                    bottom: 5vw;
    
                    .adjust-text {
                        width: 77vw;
                        font-size: 3.8vw;
                    }
                }
            }
            
            .photo-to-edit {
                position: absolute;
                width: 85vw;
                height: 85vw;
                overflow: hidden;
                object-fit: cover;
            }
        } 
    }

    .photo-montage-container-16-by-9 {    
        width: 45vw;
        display: flex;
        justify-content: flex-start;
        position: relative;

        .border-div {
            top: -1vw;
            left: -1vw;
            width: 47vw;
            height: 82.5vw;
            border: 1px solid #cccccc;
            position: absolute;
            border-radius: 2.66vw;
            box-sizing: border-box;
        }
        
        .background {
            background-size: 45vw 80vw;
            width: 45vw;
            height: 80vw;
            overflow: hidden;
            object-fit: cover;
            box-sizing: content-box;
            border: 1px solid #E3E3E3;
            border-radius: 2.66vw;
            // outline: solid 1px #cccccc;
            // outline-offset: 2px;
    
            .photo-to-pinch-container {
                mask-size: 45vw 80vw;
                --webkit-mask-size: 45vw 80vw;
    
                position: absolute;
                width: 45vw;
                height: 80vw;
                overflow: hidden;
                border-radius: 2.66vw;
    
    
    
                .photo-to-pinch {
                    position: absolute;
                    height: 80vw;
                    overflow: hidden;
                    object-fit: cover;
                }
    
                .adjust-container {
                    width: 40vw;
                    min-height: 13.3vw;
                    background-color: #ffffff;
                    opacity: 0.9;
                    // margin-left: auto;
                    // margin-right: auto;
                    left: 3vw;
                    bottom: 5vw;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    position: absolute;
    
                    .adjust-text {
                        width: 70vw;
                        font-family: var(--font-family-secondary);
                        text-align: center;
                        font-size: 3.5vw;
                        color: #000000;
                    }
                }
    
                .div-to-pinch {
                    position: static;
                    // display: flex;
                    width: 45vw;
                    height: 80vw;
                    // overflow: hidden;
                    z-index: 1000;
                    // background-color: rgba(0,0,0,0.1);
                }
            }
            
            .photo-to-edit {
                position: absolute;
                width: 45vw;
                height: 80vw;
                overflow: hidden;
                object-fit: cover;
            }
        }
    }
}

@media (min-aspect-ratio: 10/9) {
    .photo-montage-container {
        width: 100vw;
        padding-top: 1vw;
        
        .background {
            background-size: 40vw 40vw;
            width: 40vw;
            height: 40vw;
    
            .photo-to-pinch-container {
                mask-size: 40vw 40vw;
                --webkit-mask-size: 40vw 40vw;
                width: 40vw;
                height: 40vw;
    
                .photo-to-pinch {
                    height: 40vw;
                }
    
                .adjust-container {
                    width: 39vw;
                    min-height: 8vw;
                    left: 0.5vw;
                    bottom: 5vw;
    
                    .adjust-text {
                        width: 40vw;
                        font-size: 2vw;
                    }
                }
    
                .div-to-pinch {
                    width: 40vw;
                    height: 40vw;
                }
            }
            
            .photo-to-edit {
                width: 40vw;
                height: 40vw;
            }
        }
    }
}

.grayscale {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}