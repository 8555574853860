.final-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15vw;
  align-items: center;
  padding-top: 26vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  .loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.4);
    width: 100vw;
  }

  .action-row {
    width: 65vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4vw 0;
    margin-left: auto;
    margin-right: auto;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .download {
      width: 3.5vw;
    }

    .letter {
      width: 4vw;
    }

    .action-text {
      font-family: var(--font-family-primary);
      font-size: 3.75vw;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1;
      color: var(--color-secondary);
      padding: 0 2vw;
    }
  }

  .title-row {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 12vw;

    .title-line {
      width: 15vw;
      height: 1px;
      background-color: var(--color-gray);
    }

    .select-text {
      font-family: var(--font-family-primary);
      font-size: 6vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: var(--color-tertiary);
    }
  }

  .merged-photo-title {
    font-family: var(--font-family-terciary);
    // font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
    letter-spacing: 1%;
    font-size: 4.8vw;
    line-height: 4.8vw;
    color: #000000;
  }

  .find-more-photos-text {
    font-family: var(--font-family-secondary);
    font-weight: 700;
    font-size: 3.8vw;
    line-height: 4vw;
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
    margin: 1vw;
  }

  .photo-merged-container {
    width: 77vw;
    height: 77vw;
    // background-color: var(--color-secondary);
    padding: 2vw 0;
    box-sizing: border-box;
    position: relative;



    .photo-parent {
      width: 77vw;
      height: 77vw;
      padding: 0;
      box-sizing: border-box;
    }

    .photo {
      width: 77vw;
      height: 77vw;
      overflow: hidden;
      // border: solid 1px var(--color-gray);
      box-sizing: border-box;
      margin: 0 0;
      object-fit: cover;
      border: 1px solid #E3E3E3;
    }

    .photo-centered {}

    .watermark {
      position: absolute;
      margin-left: -77vw;
    }

    .sq-button {
      height: 11.7vw;
      position: absolute;
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }

    .black-edit {
      position: absolute;
      margin-top: 0vw;
      margin-left: -15vw;
      width: 15vw;
      fill: var(--color-tertiary);
    }

    .edit-text-translated {
      position: absolute;
      margin-top: 0vw;
    }

    .download-button-top-right {
      margin-top: 4vw;
      margin-left: -26vw;
      margin-bottom: 0vw;
    }

    .edit-pencil-top-right {
      margin-top: 4vw;
      margin-left: -13vw;
      margin-bottom: 0vw;
    }

    .download-button-top-left {
      margin-top: 4vw;
      margin-left: -66vw;
      margin-bottom: 0vw;
    }

    .edit-pencil-top-left {
      margin-top: 4vw;
      margin-left: -53vw;
      margin-bottom: 0vw;
    }

    .download-button-bottom-right {
      margin-top: 56.5vw;
      margin-left: -26vw;
      margin-bottom: 0vw;
    }

    .edit-pencil-bottom-right {
      margin-top: 56.5vw;
      margin-left: -13vw;
      margin-bottom: 0vw;
    }

    .download-button-bottom-left {
      margin-top: 56.5vw;
      margin-left: -66vw;
      margin-bottom: 0vw;
    }

    .edit-pencil-bottom-left {
      margin-top: 56.5vw;
      margin-left: -53vw;
      margin-bottom: 0vw;
    }

    .tap-text {
      font-family: var(--font-family-primary);
      font-size: 4vw;
      text-align: center;
      color: #000000;
      padding: 0px;
      margin: 0px;
      padding-top: 1vw;
      padding-bottom: 0vw;
    }


    .photo-facial-recognition {
      object-fit: contain;
    }

    .edit-button {
      position: absolute;
      top: 2vw;
      right: 0;
      // margin-top: 0vw;
      // margin-left: -15vw;
      // min-width: 15vw;
      height: 6vw;
      background-color: var(--color-tertiary);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex: 1;
      font-size: 3.5vw;
      font-family: var(--font-family-primary);
      color: var(--color-tertiary);
      padding-right: 2vw;
      padding-left: 2vw;
      // padding-right: 2vw;

      .pen-icon {
        margin-right: 1vw;
        fill: var(--color-primary);
        height: 5vw;
        width: 3vw;
      }

      .edit-text-translated {
        // position: absolute;
        // top: 0vw;
        // right: 2vw;
        min-width: 5vw;
        font-size: 3.5vw;
        font-family: var(--font-family-primary);
        color: var(--color-primary);
      }
    }

  }

  .upload-pick {
    margin-top: 1vw;
    background-size: 90vw 51vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    box-sizing: border-box;

    // border: solid 1px var(--color-gray);
    .upload-cload-container {
      display: flex;
      width: 69vw;
      height: 69vw;
      background-color: #ffffff;
      justify-content: center;
      align-items: center;

      .upload-cload {
        width: 37vw;
        height: 33vw;
        stroke: var(--color-primary);
        fill: var(--color-primary);
      }

      .add-photo-text {
        position: absolute;
        font-family: var(--font-family-primary);
        font-weight: 600;
        font-size: 3.75vw;
        transform: translate(0, 16vw);
      }
    }
  }

  .sticky-header {
    position: fixed;
    top: 17vw;
    left: 0vw;
    background-color: var(--color-primary);
    width: 100vw;
    z-index: 100;
    padding-top: 1vw;

    .promotional-text {
      font-family: var(--font-family-primary);
      font-size: 6vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.48px;
      text-align: center;
      color: var(--color-secondary);
      padding: 0px;
      margin: 0px;
      padding-top: 1vw;
      padding-bottom: 0vw;
    }

    .subtitle {
      font-family: var(--font-family-primary);
      font-size: 4vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.48px;
      text-align: center;
      color: var(--color-secondary);
      padding: 0px;
      margin: 0px;
      padding-top: 1vw;
      padding-bottom: 1vw;
    }
  }

  .remove-watermark-button {
    width: 50vw;
    height: 10.4vw;
    background-color: var(--color-tertiary);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    // border-radius: 5.86vw;
    margin-top: 2vw;
    text-decoration: none;
    bottom: 15vw;
    color: var(--color-secondary);
    font-family: var(--font-family-secondary);
    font-size: 5.3vw;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    border: 1px solid var(--color-tertiary);
    font-family: var(--font-family-button);
    text-transform: uppercase;



    // .get-photo-text {
    //     color: var(--color-secondary);
    //     font-family: var(--font-family-secondary);
    //     font-size: 4.28vw;
    //     font-weight: 700;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 1;
    //     margin: 0px;
    //     padding: 0px;
    // }

    .remove-watermark-transparent {
      width: 61.5vw;
      height: 13.5vw;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: transparent;
    }

    .without-watermark-text {
      color: var(--color-secondary);
      font-family: var(--font-family-secondary);
      font-size: 3.59vw;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      margin: 0px;
      padding: 0px;
    }
  }

  .remove-watermark-button:active {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }

  .search-photos {
    margin-top: 0vw;
    position: fixed;
    bottom: 10vw;
  }

  .create-another {
    background-color: #ffffff;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    position: absolute;
    bottom: 10vw;
  }

  .create-another-contrast {
    color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary);
  }


  .rydercup__fantastic-photo-svg {
    position: fixed;
    bottom: 10vw;
  }

  .try-fantastic {
    width: 70vw;
  }

  .press-and-hold-text {
    width: 80vw;
    font-family: var(--font-family-primary);
    font-weight: 400;
    text-align: center;
    color: #000000;
    font-size: 3.6vw;
    display: none;
  }

  .fantastic-photos-logo {
    margin-top: 0vw;
  }

  .not-you {
    font-family: var(--font-family-secondary);
    font-size: 4.3vw;
    color: #000000;
    font-weight: 4000;
    margin: 1vw;
  }

  .not-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .photo-merged-container-16-by-9 {
    width: 47.81vw;
    height: 85vw;
    // background-color: var(--color-secondary);
    padding: 0vw 0;
    box-sizing: border-box;
    position: relative;
    border-radius: 2.66vw;
    // outline: solid 1px #cccccc;
    // outline: solid 1px #ff0000;
    // outline-offset: 2px;
    border: solid 1px #cccccc;

    .edit-button-container {
      position: absolute;
      width: 100%;
      height: 8vw;
      // background: var(--color-quaternary);
      background: var(--color-tertiary);
      // opacity: 0.9;
      top: 0vw;
      border-radius: 2.66vw 2.66vw 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 3vw;
      box-sizing: border-box;
      display: none;

      .edit-button-top {
        color: #ffffff;
        font-family: var(--font-family-primary);
        text-transform: uppercase;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        font-size: 3.73vw;
        font-weight: 800;
      }
    }

    .photo-parent {
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
      border-radius: 2.66vw;
    }

    .photo {
      width: 100%;
      height: 100%;
      overflow: hidden;
      // border: solid 1px var(--color-gray);
      box-sizing: border-box;
      margin: 0 0;
      border-radius: 2.66vw;
      object-fit: cover;
    }

    .photo-centered {}

    .photo-facial-recognition {
      object-fit: contain;
    }

    .watermark {
      position: absolute;
      margin-left: -85vw;
    }

    .sq-button {
      height: 11.7vw;
      position: absolute;
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }

    .black-edit {
      position: absolute;
      margin-top: 0vw;
      margin-left: -15vw;
      width: 15vw;
      fill: var(--color-tertiary);
      display: none;
    }

    .edit-button {
      position: absolute;
      top: 2vw;
      right: 0;
      // margin-top: 0vw;
      // margin-left: -15vw;
      // min-width: 15vw;
      height: 6vw;
      background-color: var(--color-tertiary);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex: 1;
      font-size: 3.5vw;
      font-family: var(--font-family-primary);
      color: var(--color-tertiary);
      padding-right: 2vw;
      padding-left: 2vw;
      display: none;
      // padding-right: 2vw;

      .pen-icon {
        margin-right: 1vw;
        fill: var(--color-primary);
        height: 5vw;
        width: 3vw;
      }

      .edit-text-translated {
        // position: absolute;
        // top: 0vw;
        // right: 2vw;
        min-width: 5vw;
        font-size: 3.5vw;
        font-family: var(--font-family-primary);
        color: var(--color-primary);
      }
    }

    .tap-text {
      font-family: var(--font-family-primary);
      font-size: 4vw;
      text-align: center;
      color: #000000;
      padding: 0px;
      margin: 0px;
      padding-top: 1vw;
      padding-bottom: 0vw;
    }

  }

  .photo-merged-container-16-by-9-registration-complete {
    width: 42.18vw;
    height: 75vw;
  }


  .cta-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95vw;
    position: absolute;
    bottom: 12vw;

    .save-share-button {
      min-width: 30vw;
      height: 10.4vw;
      background-color: var(--color-tertiary);
      justify-content: center;
      align-items: center;
      display: flex;
      text-decoration: none;
      color: var(--color-secondary);
      font-family: var(--font-family-secondary);
      font-size: 5.3vw;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      border: 1px solid var(--color-tertiary);
      font-family: var(--font-family-button);
      text-transform: uppercase;
      padding: 0 2.5vw;

      .save-share-transparent {
        width: 46.93vw;
        height: 10.4vw;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
      }

    }

    .save-share-button:active {
      background-color: var(--color-secondary);
      color: var(--color-primary);
    }

    .edit-button {
      background-color: #ffffff;
      color: var(--color-tertiary);
    }
  }

  .feedback {
    font-family: var(--font-family-button);
    font-size: 4.27vw;
    line-height: 6.40vw;
    color: var(--color-secondary);
    position: absolute;
    bottom: 32vw;
    text-decoration: underline;
  }
}



@media (max-aspect-ratio: 9/15) {

  .final-photo-container {
    padding-bottom: 15vw;
    padding-top: 23vw;

    .merged-photo-title {
      letter-spacing: 1%;
      font-size: 4.8vw;
      line-height: 4.8vw;
    }

    .find-more-photos-text {
      font-weight: 700;
      font-size: 3.8vw;
      line-height: 4vw;
      color: #003C82;
    }

    .photo-merged-container {
      width: 85vw;
      height: 85vw;
      // background-color: var(--color-secondary);
      padding: 2vw 0;
      box-sizing: border-box;
      position: relative;

      .photo-parent {
        width: 85vw;
        height: 85vw;
        padding: 0;
        box-sizing: border-box;
      }

      .photo {
        width: 85vw;
        height: 85vw;
        overflow: hidden;
        // border: solid 1px var(--color-gray);
        box-sizing: border-box;
        margin: 0 0;
        object-fit: cover;
      }

      .photo-centered {}

      .photo-facial-recognition {
        object-fit: contain;
      }

      .watermark {
        position: absolute;
        margin-left: -85vw;
      }

      .sq-button {
        height: 11.7vw;
        position: absolute;
        fill: var(--color-primary);
        stroke: var(--color-primary);
      }

      .black-edit {
        position: absolute;
        margin-top: 0vw;
        margin-left: -15vw;
        width: 15vw;
        fill: var(--color-tertiary);
      }

      .edit-button {
        position: absolute;
        top: 2vw;
        right: 0;
        // margin-top: 0vw;
        // margin-left: -15vw;
        // min-width: 15vw;
        height: 6vw;
        background-color: var(--color-tertiary);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        font-size: 3.5vw;
        font-family: var(--font-family-primary);
        color: var(--color-tertiary);
        padding-right: 2vw;
        padding-left: 2vw;
        // padding-right: 2vw;

        .pen-icon {
          margin-right: 1vw;
          fill: var(--color-primary);
          height: 5vw;
          width: 3vw;
        }

        .edit-text-translated {
          // position: absolute;
          // top: 0vw;
          // right: 2vw;
          min-width: 5vw;
          font-size: 3.5vw;
          font-family: var(--font-family-primary);
          color: var(--color-primary);
        }
      }


      .download-button-top-right {
        margin-top: 4vw;
        margin-left: -26vw;
        margin-bottom: 0vw;
      }

      .edit-pencil-top-right {
        margin-top: 4vw;
        margin-left: -13vw;
        margin-bottom: 0vw;
      }

      .download-button-top-left {
        margin-top: 4vw;
        margin-left: -66vw;
        margin-bottom: 0vw;
      }

      .edit-pencil-top-left {
        margin-top: 4vw;
        margin-left: -53vw;
        margin-bottom: 0vw;
      }

      .download-button-bottom-right {
        margin-top: 56.5vw;
        margin-left: -26vw;
        margin-bottom: 0vw;
      }

      .edit-pencil-bottom-right {
        margin-top: 56.5vw;
        margin-left: -13vw;
        margin-bottom: 0vw;
      }

      .download-button-bottom-left {
        margin-top: 56.5vw;
        margin-left: -66vw;
        margin-bottom: 0vw;
      }

      .edit-pencil-bottom-left {
        margin-top: 56.5vw;
        margin-left: -53vw;
        margin-bottom: 0vw;
      }

      .tap-text {
        font-family: var(--font-family-primary);
        font-size: 4vw;
        text-align: center;
        color: #000000;
        padding: 0px;
        margin: 0px;
        padding-top: 1vw;
        padding-bottom: 0vw;
      }

    }


    .photo-merged-container-16-by-9 {
      width: 60vw;
      height: 105vw;
      // background-color: var(--color-secondary);
      padding: 0vw 0;
      box-sizing: border-box;
      position: relative;
      border-radius: 2.66vw;
      // outline: solid 1px #cccccc;
      // outline: solid 1px #ff0000;
      // outline-offset: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5vw;
      // box-shadow: 0px 0px 0px 1px #ffffff inset;
      border: solid 1px #cccccc;

      .photo-parent {
        width: 100%;
        height: 100%;
        padding: 0;
        box-sizing: border-box;
        border-radius: 2.66vw;
      }

      .photo {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // border: solid 1px var(--color-gray);
        box-sizing: border-box;
        margin: 0 0;
        border-radius: 2.66vw;
        object-fit: cover;
      }

      .photo-centered {}

      .photo-facial-recognition {
        object-fit: contain;
      }

      .watermark {
        position: absolute;
        margin-left: -85vw;
      }

      .sq-button {
        height: 11.7vw;
        position: absolute;
        fill: var(--color-primary);
        stroke: var(--color-primary);
      }

      .black-edit {
        position: absolute;
        margin-top: 0vw;
        margin-left: -15vw;
        width: 15vw;
        fill: var(--color-tertiary);
        display: none;
      }

      .edit-button {
        position: absolute;
        top: 2vw;
        right: 0;
        // margin-top: 0vw;
        // margin-left: -15vw;
        // min-width: 15vw;
        height: 6vw;
        background-color: var(--color-tertiary);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        font-size: 3.5vw;
        font-family: var(--font-family-primary);
        color: var(--color-tertiary);
        padding-right: 2vw;
        padding-left: 2vw;
        display: none;
        // padding-right: 2vw;

        .pen-icon {
          margin-right: 1vw;
          fill: var(--color-primary);
          height: 5vw;
          width: 3vw;
        }

        .edit-text-translated {
          // position: absolute;
          // top: 0vw;
          // right: 2vw;
          min-width: 5vw;
          font-size: 3.5vw;
          font-family: var(--font-family-primary);
          color: var(--color-primary);
        }
      }

      .tap-text {
        font-family: var(--font-family-primary);
        font-size: 4vw;
        text-align: center;
        color: #000000;
        padding: 0px;
        margin: 0px;
        padding-top: 1vw;
        padding-bottom: 0vw;
      }

    }

    .photo-merged-container-16-by-9-registration-complete {
      width: 54.28vw;
      height: 95vw;
    }

    .not-you {
      margin: 3vw 0 0vw 0;
    }

    .find-more-photos-text {
      margin: 1vw;
    }

    .upload-pick {
      margin-top: 1vw;
      background-size: 90vw 51vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3vw;
      box-sizing: border-box;

      // border: solid 1px var(--color-gray);
      .upload-cload-container {
        display: flex;
        width: 69vw;
        height: 69vw;
        background-color: #ffffff;
        justify-content: center;
        align-items: center;

        .upload-cload {
          width: 37vw;
          height: 33vw;
          stroke: var(--color-primary);
          fill: var(--color-primary);
        }

        .add-photo-text {
          position: absolute;
          font-family: var(--font-family-primary);
          font-weight: 600;
          font-size: 3.75vw;
          transform: translate(0, 16vw);
        }
      }
    }

    .sticky-header {
      position: fixed;
      top: 17vw;
      left: 0vw;
      background-color: var(--color-primary);
      width: 100vw;
      z-index: 100;
      padding-top: 1vw;

      .promotional-text {
        font-family: var(--font-family-primary);
        font-size: 6vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.48px;
        text-align: center;
        color: var(--color-secondary);
        padding: 0px;
        margin: 0px;
        padding-top: 1vw;
        padding-bottom: 0vw;
      }

      .subtitle {
        font-family: var(--font-family-primary);
        font-size: 4vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.48px;
        text-align: center;
        color: var(--color-secondary);
        padding: 0px;
        margin: 0px;
        padding-top: 1vw;
        padding-bottom: 1vw;
      }
    }

    .remove-watermark-button {
      width: 50vw;
      height: 10.4vw;
      background-color: var(--color-tertiary);
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      // border-radius: 5.86vw;
      margin-top: 6vw;
      text-decoration: none;
      bottom: 15vw;
      color: var(--color-secondary);
      font-family: var(--font-family-secondary);
      font-size: 5.3vw;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      border: 1px solid var(--color-tertiary);
      font-family: var(--font-family-button);
      text-transform: uppercase;
      // display: none;

      // .get-photo-text {
      //     color: var(--color-secondary);
      //     font-family: var(--font-family-secondary);
      //     font-size: 4.28vw;
      //     font-weight: 700;
      //     font-stretch: normal;
      //     font-style: normal;
      //     line-height: 1;
      //     margin: 0px;
      //     padding: 0px;
      // }

      .remove-watermark-transparent {
        width: 61.5vw;
        height: 13.5vw;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
      }

      .without-watermark-text {
        color: var(--color-secondary);
        font-family: var(--font-family-secondary);
        font-size: 3.59vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        margin: 0px;
        padding: 0px;
      }
    }

    .remove-watermark-button:active {
      background-color: var(--color-secondary);
      color: var(--color-primary);
    }

    .cta-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 95vw;
      position: absolute;
      bottom: 15vw;

      .save-share-button {
        min-width: 30vw;
        height: 10.4vw;
        background-color: var(--color-tertiary);
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        color: var(--color-secondary);
        font-family: var(--font-family-secondary);
        font-size: 5.3vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        border: 1px solid var(--color-tertiary);
        font-family: var(--font-family-button);
        text-transform: uppercase;

        .save-share-transparent {
          width: 40vw;
          height: 10.4vw;
          justify-content: center;
          align-items: center;
          display: flex;
          background-color: transparent;
        }

      }

      .save-share-button:active {
        background-color: var(--color-secondary);
        color: var(--color-primary);
      }

      .edit-button {
        background-color: #ffffff;
        color: var(--color-tertiary);
      }
    }

    .search-photos {
      margin-top: 0vw;
      position: fixed;
      bottom: 13.5vw;
    }

    .create-another {
      background-color: #ffffff;
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
    }


    .create-another-contrast {
      color: var(--color-tertiary);
      border: 1px solid var(--color-tertiary);
    }

    .try-fantastic {
      width: 80vw;
    }

    .rydercup__fantastic-photo-svg {
      position: fixed;
      bottom: 10vw;
    }

    .press-and-hold-text {
      width: 85vw;
      font-family: var(--font-family-secondary);
      font-weight: 400;
      text-align: center;
      color: #000000;
      font-size: 3.9vw;
      display: none;
    }

    .absolute-more-photos {
      position: absolute;
      bottom: 29vw;
    }

    .fantastic-photos-logo {
      margin-top: 0vw;
    }
  }
}

@media (min-aspect-ratio: 10/9) {
  .final-photo-container {
    padding-bottom: 15vw;
    padding-top: 2vw;

    .fantastic-photos-logo {
      margin-top: 0vw;
      margin-bottom: 1vw;
      width: 30vw;
      height: auto;
    }

    .photo-merged-container {
      width: 35vw;
      height: 35vw;
      padding: 0vw 0;
      margin-top: 1vw;

      .photo-parent {
        width: 35vw;
        height: 35vw;

        .black-edit {
          position: absolute;
          margin-top: 0vw;
          margin-left: -60px;
          width: 60px;
          fill: var(--color-tertiary);
        }

      }

      .photo {
        width: 35vw;
        height: 35vw;
      }

      .photo-centered {}

      .watermark {
        position: absolute;
        margin-left: -35vw;
      }

      .sq-button {}

    }

    .remove-watermark-button {
      width: 30vw;
      height: 5vw;
      margin-top: 0vw;
      position: fixed;
      bottom: 3.5vw;
      font-size: 2vw;
      cursor: pointer;

      .remove-watermark-transparent {
        width: 30vw;
        height: 5vw;
      }

      .without-watermark-text {}
    }
  }
}

.registration-complete__container {
  padding-top: 15vw;
  justify-content: flex-start;

  .registration-complete__content--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .registration-complete__title {
      font-family: var(--font-family-header);
      width: 85vw;
      text-align: left;
      font-size: 5.87vw;
      line-height: 6.67vw;
      color: var(--color-dark-grey);
      font-weight: 400;
      margin: 0;
      text-transform: none;
    }

    .separator-line {
      margin-left: 0;
    }

    .registration-complete__subtitle {
      font-family: var(--font-family-header);
      width: 85vw;
      text-align: left;
      font-size: 4.27vw;
      line-height: 5.07vw;
      color: var(--color-dark-grey);
      font-weight: 400;
      margin: 2vw 0 0 0;
      text-transform: none;
    }

    .registration-complete__enjoy--text {
      font-family: var(--font-family-header);
      width: 85vw;
      text-align: left;
      font-size: 4vw;
      line-height: 4.80vw;
      color: var(--color-tertiary);
      font-weight: 400;
      margin: 2vw 0 0 0;
      text-transform: none;
    }
  }

}