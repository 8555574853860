.home-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 21vw;
    padding-bottom: 12vw;
    align-items: center;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    position: fixed;
    
    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.4);
        width: 100vw;
    }

    .home-screen-img {
        width: 82vw;
        margin-top: 1vw;
    }

    .start-button {
        min-width: 53.3vw;
        height: 10.4vw;
        background-color: var(--color-tertiary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 1vw;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
        position: fixed;
        bottom: 15vw;
        color: var(--color-secondary);
        font-family: var(--font-family-button);
        font-size: 5.3vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        text-transform: uppercase;
        padding: 0 3vw;
        box-sizing: border-box;

        .start-button-transparent {
            min-width: 50.3vw;
            height: 10.4vw;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: transparent;

        }
    }

    .start-button:active {
        background-color: var(--color-secondary);
        color: var(--color-tertiary);
    }

    // .start-button-text {
    //     color: var(--color-secondary);
    //     font-family: var(--font-family-primary);
    //     font-size: 3.76vw;
    //     font-weight: 600;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 2;
    //     letter-spacing: 1.2px;
    //     text-transform: uppercase;
    //     margin: 0px;
    //     padding: 0px;
    // }
    
    .no-photos-found {
        font-family: var(--font-family-primary);
        color: var(--color-tertiary);
        font-size: 3.2vw;
        font-weight: 600;
        position: fixed;
        bottom: 14vw;
        text-align: center;
    }

    .create-video {
        bottom: 30vw;
    }
}

.loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (min-aspect-ratio: 9/15) {
    .home-container {
        
        .loading-spinner-container {
        }
    
        .home-screen-img {
        }
    
        .start-button {
        }
    
        .start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 3vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 1.2px;
            margin: 0px;
            padding: 0px;
        }

        .no-photos-found {
            font-family: var(--font-family-primary);
            color: var(--color-tertiary);
            font-size: 3.2vw;
            font-weight: 600;
            position: fixed;
            bottom: 12vw;
            text-align: center;
        }
    }
    
    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-aspect-ratio: 10/9) {
    .home-container {
        padding-top: 1vw;

        .start-button {
            min-width: 30vw;
            height: 5vw;
            margin-top: 0vw;
            position: fixed;
            bottom: 3vw;
            font-size: 2vw;
    
            .start-button-transparent {
                min-width: 30vw;
                height: 5vw;
                cursor: pointer;
            }
        }
    
        .start-button:active {
            background-color: #990000;
            color: #ffffff;
        }
    }
}