.loading-screen-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0vw;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 201;
    align-items: center;
    box-sizing: border-box;
    background-color: #ffffff;
    
    // background-color: rgba($color: #000000, $alpha: 0.7);

    .loading-screen-content-container {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 15vw;
        bottom: 0vw;
        left: 0;
        right: 0;
        z-index: 200;
        align-items: center;
        background-color: #ffffff;
        padding-top: 25vw;
        box-sizing: border-box;

        .close-x {
            margin-left: auto;
        }


        .loading-screen-content {
            display: flex;
            flex-direction: column;
            position: relative;
            // top: 66.5vh;
            margin: auto;
            padding-bottom: 30vw;
            box-sizing: border-box;
    
            .loading-advice-text {
                font-family: var(--font-family-primary);
                margin: 0;
                font-size: 4.8vw;
                color: var(--color-primary);
            }

            .contrast-loading-advice-text {
                color: var(--color-tertiary)
            }

            // .loading-text {
            //     width: 100%;
            //     font-family: var(--font-family-secondary);
            //     font-weight: 700;
            //     font-size: 3.5vw;
            //     color: #000000;
            //     text-align: center;
            //     margin-top: 1vw;
            // }
    
            .loading-screen-only-bar-container {
                display: flex;
                flex-direction: column;
                height: 25vw;
                align-items: center;
                margin-bottom: -3.2vw;
    
                .loading-screen-bar {
    
                    transform: scale(0.1, 1);
                    transition: transform 0.3s ease;
                    animation-name: loading-animation;
                    animation-duration: 10s;
                    animation-iteration-count: infinite;
        
                    &:nth-child(2) { animation-delay: 0.25s; }
                }
                @keyframes loading-animation {
                    0% { transform: scale(0.0, 1) }
                    100% { transform: scale(2.7, 1) }
                }

                .loading-screen-text {
                    width: 100%;
                    font-family: var(--font-family-primary);
                    font-weight: 500;
                    font-size: 3.5vw;
                    color: var(--color-primary);
                    text-align: center;
                    margin-top: 0vw;
                }

                .contrast-loading-screen-text {
                    color: var(--color-tertiary);
                }
            }
    
            .loading-screen-logo-container {
                display: flex;
                height: 15vw;
                align-items: center;
                margin-bottom: -12.5vw;
    
                transform: translate(0vw, 0vw);
                transition: transform 0.3s ease;
                animation-name: loading-screen-logo-animation;
                animation-duration: 10s;
                animation-iteration-count: infinite;
                    
                    &:nth-child(2) { animation-delay: 0.25s; }
    
                .loading-screen-logo {
                    position: relative;
                    width: 13.3vw;
                }
    
                @keyframes loading-screen-logo-animation {
                    0% { transform: translate(8vw, 0vw) }
                    100% { transform: translate(47vw, 0vw) }
                }
            }
        }
    }



    .loading-bar-content-cloud {
        display: flex;
        flex-direction: column;
        position: relative;
        top: unset;
        // transform: translate(0, 76%);
    }
}