.oracle-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 22, 22, 0.9);
  z-index: 202;
}

.modal-container {
  width: 90.6vw;
  min-height: 160vw;
  padding-bottom: 5vw;
  background: transparent;
  box-sizing: border-box;
  border: none;
  outline: transparent;
  padding-top: 10vw;

  .close-modal-container {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding: 4vw 0vw;

    .close-modal-x {
      cursor: pointer;
    }
  }

  .oracle-modal-content {
    width: 90.6vw;
    min-height: 140vw;
    background: #FFFFFF;
    padding: 5vw 5vw 5vw 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .menu-close {
      width: 4.35vw;
      height: 4.35vw;
      position: absolute;
      right: 3.89vw;
    }

   .register-title {
      font-family: var(--font-family-primary);
      text-align: left;
      font-weight: 400;
      color: var(--color-dark-grey);
      font-size: 5.33vw;
      line-height: 7.2vw;
      padding-top: 2vw;
      margin: 4.6vw 0 0 0;
      letter-spacing: 1%;
    }

    .error {
      height: 2vw;
      font-family: var(--font-family-primary);
      text-align: left;
      font-weight: 300;
      color: #DB3232;
      font-size: 3.73vw;
      line-height: 4.48vw;
      padding-top: 0vw;
      margin: 3vw 0 0 0;
      letter-spacing: 1%;
    }

    .dark-cyan-title {
      color: var(--color-tertiary);
    }

    .register-subtitle {
      font-family: var(--font-family-primary);
      text-align: left;
      font-weight: 400;
      color: var(--color-dark-grey);
      margin: 0;
      font-size: 4.8vw;
    }

    .register-unlock-animation-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .register-confetti {
        position: absolute;
      }
    }

    .form-container {
      padding-top: 0vw;

      .input-container {
        width: 78.93vw;
        margin-top: 3vw;

        .input-label {
          font-family: var(--font-family-secondary);
          font-size: 3.2vw;
          line-height: 3.2vw;
          color: var(--color-dark-grey);

          .required {
            color: var(--color-dark-grey);
          }
        }

        .input-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .input-firstname {
            width: 78.93vw;
            height: 11.2vw;
            padding: 0vw 2.93vw;
            box-sizing: border-box;
            font-size: 3.8vw;
            letter-spacing: 1%;
            font-family: var(--font-family-secondary);
            // color: #000000;
            border-radius: 4px;
            background-color: #ffffff;
            border-style: solid;
            border-color: #D9DFE3;
          }

          .input-wrong-state {
            border-color: var(--color-primary);
            border-width: 2px;
            border-style: solid;
          }

          .input-error {
            border-color: #C70000;
          }
        }

      }
    }

    .consent-container {
      display: flex;
      flex-direction: row;
      padding: 5vw 0;
      width: 79vw;

      .consent-check-container {
        padding-right: 1.5vw;

        .consent-check {
          fill: #004F6A;
          stroke: #004F6A;
        }
      }

      .agree-text {
        font-family: var(--font-family-secondary);
        font-size: 3.2vw;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        color: #004F6A;

        .agree-text-link {
          font-size: 3.2vw;
          text-decoration: underline;
          font-weight: 600;
          color: #004F6A;
        }
      }
    }

    .submit-button {
      width: 79.47vw;
      height: 12vw;
      background-color: #00838F;
      border-radius: 0.53vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      font-family: var(--font-family-secondary);
      font-size: 3.8vw;
      color: #FFFFFF;

      .submit-button-text {
        font-family: var(--font-family-secondary);
        font-size: 5.33vw;
        margin: 0;
        padding: 0;
        color: inherit;
      }
    }

    .disabled {
      opacity: 0.5;
    }

    .submit-button-available {
      background-color: var(--color-primary-action);
      color: #ffffff;
    }

    // .submit-button-available:active {
    //     background-color: #000000;
    //     color: #ffffff;
    // }

    .submit-button-available:focus {
      background-color: #000000;
      color: #ffffff;
    }

    .submit-button-available:target {
      background-color: #000000;
      color: #ffffff;
    }

    .edit-pressed {
      background-color: #990000;
      color: #ffffff;
    }

  }

}

@media (max-aspect-ratio: 9/15) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(22, 22, 22, 0);
    z-index: 202;
  }

  .modal-container {
    width: 90.6vw;
    min-height: 150vw;
    padding-bottom: 5vw;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: transparent;
    padding-top: 25vw;

    .close-modal-container {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      padding: 4vw 0vw;

      .close-modal-x {
        cursor: pointer;
      }
    }

    .modal-content {
      width: 90.6vw;
      min-height: 130vw;
      background: #00234B;
      padding: 5vw 5vw 5vw 5vw;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .menu-close {
        width: 6.4vw;
        height: 6.4vw;
        position: absolute;
        margin-left: -5vw;
      }


      .register-title {
        font-family: var(--font-family-terciary);
        text-align: center;
        font-weight: 700;
        color: #ffffff;
        font-size: 4.8vw;
        line-height: 6vw;
        padding-top: 2vw;
        margin: 0;
        letter-spacing: 1%;
      }

      .form-container {
        padding-top: 6vw;

        .input-container {
          width: 83vw;
          margin-top: 4.2vw;

          .input-label {
            font-family: var(--font-family-secondary);
            font-size: 3.2vw;
            line-height: 3.2vw;
            color: #ffffff;

            .required {
              color: #ffffff;
            }
          }

          .input-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .input-firstname {
              width: 77.3vw;
              height: 11.2vw;
              padding: 0 vw;
              box-sizing: border-box;
              font-size: 3.8vw;
              letter-spacing: 1%;
              font-family: var(--font-family-secondary);
              color: #000000;
              border-radius: 4px;
              background-color: #ffffff;
            }

            .input-wrong-state {
              // border-color: var(--color-primary);
              // border-width: 2px;
              // border-style: solid;
            }
          }

        }
      }

      .consent-container {
        display: flex;
        flex-direction: row;
        padding: 3vw 0;
        width: 79vw;

        .consent-check-container {
          padding-right: 1.5vw;

          .consent-check {
            fill: #004F6A;
            stroke: #004F6A;
          }
        }

        .agree-text {
          font-family: var(--font-family-secondary);
          font-size: 2.14vw;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          color: #ffffff;

          .agree-text-link {
            font-size: 2.14vw;
            text-decoration: underline;
            font-weight: 700;
            color: #004F6A;
          }
        }
      }

      .submit-button {
        width: 69.33vw;
        height: 12vw;
        background-color: rgba($color: #C81414, $alpha: 0.4);
        border-radius: 5.86vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        font-family: var(--font-family-secondary);
        font-size: 3.8vw;
        color: rgba($color: #ffffff, $alpha: 0.4);

        .submit-button-text {
          font-family: var(--font-family-secondary);
          font-size: 3.8vw;
          margin: 0;
          padding: 0;
          color: inherit;
        }
      }

      .submit-button-available {
        background-color: var(--color-primary-action);
        color: #ffffff;
      }

      // .submit-button-available:active {
      //     background-color: #000000;
      //     color: #ffffff;
      // }

      .submit-button-available:focus {
        background-color: #000000;
        color: #ffffff;
      }

      .submit-button-available:target {
        background-color: #000000;
        color: #ffffff;
      }

      .edit-pressed {
        background-color: #990000;
        color: #ffffff;
      }

    }

  }
}