.facial-search-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 26vw;
    padding-bottom: 12vw;
    align-items: center;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    position: fixed;
    
    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.4);
        width: 100vw;
    }

    .facial-search-menu-screen-img {
        width: 82vw;
        margin-top: 1vw;
    }

    .facial-search-menu__promotional-text {
        font-family: var(--font-family-header);
        font-size: 8vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 8vw;
        letter-spacing: 2px;
        text-align: center;
        color: var(--color-tertiary);
        margin: 0;
        text-transform: uppercase;

        .facial-search-menu__title-bold {
            font-weight: 800;
        }
    }

    .facial-search-menu__subtitle {
        font-family: var(--font-family-header);
        font-size: 4.26vw;
        font-weight: 500;
        color: var(--color-tertiary);
        text-align: center;
        margin: 2vw 0 0 0;
        letter-spacing: 2px;
        justify-content: center;
        display: flex;
        align-items: center;
        background-color: #ffffff;

        .facial-search-menu__line {
            position: absolute;
            width: 80vw;
            height: 1px;
            background-color: var(--color-tertiary);
            margin: 0vw 0;
        }        
        .facial-search-menu__white-line {
            position: absolute;
            width: 30vw;
            height: 2px;
            background-color: #ffffff;
            margin: 0vw 0;
        }

        .facial-search-menu__subtitle-text {
            z-index: 100;
        }
    }

    .facial-search-menu_main-content {
        display: flex;
        flex-direction: column;
        width: inherit;
        justify-content: space-around;
        align-items: center;
        flex: 1;
        
        .start-button {
            min-width: 60vw;
            height: 10.4vw;
            background-color: var(--color-tertiary);
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 2.66vw;
            margin-top: 5vw;
            text-decoration: none;
            color: var(--color-secondary);
            font-family: var(--font-family-button);
            font-size: 5.3vw;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            text-transform: uppercase;
            border: none;
            box-shadow: 4px 4px 4px rgba(26, 36, 67, 0.1);
    
            .start-button-transparent {
                width: 53.3vw;
                height: 10.4vw;
                justify-content: center;
                align-items: center;
                display: flex;
                background-color: transparent;
    
            }
        }
    
        .start-button:active {
            background-color: var(--color-secondary);
            color: var(--color-tertiary);
        }
    
        .facial-search-menu__img {
            width: 64vw;
        }
    }



    .no-photos-found {
        font-family: var(--font-family-primary);
        color: var(--color-tertiary);
        font-size: 3.2vw;
        font-weight: 600;
        position: fixed;
        bottom: 14vw;
        text-align: center;
    }
}

.loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-aspect-ratio: 9/15) {
    .facial-search-menu-container {
        
        .loading-spinner-container {
        }
    
        .facial-search-menu-screen-img {
        }
    
        .start-button {
            min-width: 60vw;
            height: 11vw;
            background-color: var(--color-tertiary);
            justify-content: center;
            align-items: center;
            display: flex;
            margin-top: 5vw;
            text-decoration: none;
            font-size: 5vw;
        }
    
        .start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 3vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 1.2px;
            margin: 0px;
            padding: 0px;
        }

        .no-photos-found {
            font-family: var(--font-family-primary);
            color: var(--color-tertiary);
            font-size: 3.2vw;
            font-weight: 600;
            text-align: center;
        }
    }
    
    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-aspect-ratio: 10/9) {
    .facial-search-menu-container {
        padding-top: 1vw;

        .start-button {
            min-width: 30vw;
            height: 5vw;
            margin-top: 0vw;
            font-size: 2vw;
    
            .start-button-transparent {
                width: 30vw;
                height: 5vw;
                cursor: pointer;
            }
        }
    
        .start-button:active {
            background-color: #990000;
            color: #ffffff;
        }
    }
}