.user-identity__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 20vw;
  box-sizing: border-box;
  flex: 1;
  display: flex;

  .user-identity__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 1;

    .user-identity__title {
      font-family: var(--font-family-header);
      width: 85vw;
      text-align: left;
      font-size: 5.87vw;
      line-height: 6.67vw;
      color: var(--color-dark-grey);
      font-weight: 400;
      margin: 0;
      text-transform: none;
    }

    .user-identity__text {
      font-family: var(--font-family-header);
      width: 85vw;
      text-align: left;
      font-size: 4.27vw;
      line-height: 5.07vw;
      color: var(--color-dark-grey);
      font-weight: 300;
    }


    .user-identity__cta-cutton--container {
      position: absolute;
      bottom: 15vw;
      width: 85.33vw;
      height: 12.80vw;

      .user-identity__cta-button {
        width: 100%;
        height: 100%;
        border-radius: 1.33vw;
        box-sizing: border-box;
        background-color: var(--color-tertiary);
        font-family: var(--font-family-button);
        font-weight: 600;
        font-size: 5.33vw;
        line-height: 7.2vw;
        color: var(--color-secondary);
        padding: 0;
        border: none;
        text-transform: capitalize;
      }

      .disabled {
        opacity: 0.5;
      }

      
    .loading-spinner {
      width: 12.80vw;
      height: 12.80vw;
      object-fit: cover;
    }
    }
  }
}