// Patriots
:root {
    --color-primary: #ffffff;
    --color-secondary: #022754;
    --color-tertiary: #E10D3E;
    --color-quaternary: #b0b7bc;
    --color-primary-action: #c62033;
    --color-dark-gray: #292929;
    --color-gray: #949494;
    --color-black: #000000;
    --color-green: #208a16;
    --color-pb-footer: #ffffff;

    --font-family-primary: 'Asap Condensed', Roboto, 'Libre Franklin';
    --font-family-secondary: 'Asap Condensed', Roboto, 'Libre Franklin';
    --font-family-header: 'Asap Condensed', Roboto, 'Libre Franklin';
    --font-family-body: var(--font-family-primary);
    --font-family-button: var(--font-family-primary);
}