@font-face {
    font-family: 'Merriweather';
    font-weight: 400;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 400;
    font-style: italic;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 500;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-Regular.ttf) format('truetype');
}


@font-face {
    font-family: 'Merriweather';
    font-weight: 500;
    font-style: italic;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-Italic.ttf) format('truetype');
}


@font-face {
    font-family: 'Merriweather';
    font-weight: 600;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 600;
    font-style: italic;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-BoldItalic.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Merriweather';
    font-weight: 800;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Merriweather';
    font-weight: 800;
    font-style: italic;
    src: local('Merriweather'), url(../../fonts/merriweather/Merriweather-BlackItalic.ttf) format('truetype');
}
  