.loading-bar-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 201;
    align-items: center;

    .loading-background {
        position: absolute;
        top: 0vw;
        bottom: 0vw;
        left: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }

    .loading-bar-content {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 40vh;

        .loading-text {
            width: 100%;
            font-family: var(--font-family-primary);
            font-weight: 600;
            font-size: 3.5vw;
            color: var(--color-tertiary);
            text-align: center;
            margin-top: -5vw;
        }

        .loading-only-bar-container {
            display: flex;
            height: 15vw;
            align-items: center;
            margin-bottom: 0vw;

            .loading-bar {

                transform: scale(0.1, 1);
                transition: transform 0.3s ease;
                animation-name: loading-animation;
                animation-duration: 40s;
                animation-iteration-count: infinite;
    
                &:nth-child(2) { animation-delay: 0.25s; }
            }
            @keyframes loading-animation {
                0% { transform: scale(0.1, 1) }
                100% { transform: scale(2.7, 1) }
            }
        }

        .loading-logo-container {
            display: flex;
            height: 15vw;
            align-items: center;
            margin-bottom: -5vw;

            transform: translate(0vw, 0vw);
            transition: transform 0.3s ease;
            animation-name: loading-animation-smile;
            animation-duration: 40s;
            animation-iteration-count: infinite;
                
                &:nth-child(2) { animation-delay: 0.25s; }

            .loading-logo {
                position: relative;
                width: 8vw;
                margin-top: 8vw;
            }

            @keyframes loading-animation-smile {
                0% { transform: translate(-2vw, 0vw) }
                100% { transform: translate(45vw, 0vw) }
            }
        }
    }

    .loading-bar-content-cloud {
        display: flex;
        flex-direction: column;
        position: relative;
        top: unset;
        // transform: translate(0, 76%);
    }
}

.loading-bar-container-cloud {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 200;
    align-items: center;
    top:unset;
    bottom: unset;
    left: unset;
    right: unset;
}