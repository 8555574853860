.take-photo-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // padding-top: 26vw;
  box-sizing: border-box;
  align-items: center;

  .display-none {
    display: none !important;
  }

  .take-photo-title {
    font-family: var(--font-family-header);
    width: 70vw;
    text-align: center;
    font-size: 4.2vw;
    color: var(--color-secondary);
    font-weight: 900;
    padding: 5.6vw 0 5.6vw 0;
    margin: 0;
    text-transform: uppercase;
    z-index: 1500;
    position: absolute;
    top: 24vw;

    .separator-line {
      display: none;
    }
  }


  .take-photo-content-container {
    padding: 40vw 0px 11vw 0vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;

    .video-player {
      width: 100vw;
      // height: 50vh;
      object-fit: cover;
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
    }

    .hide-feed {
      display: none;
    }

    .take-photo-flash {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.8);
    }

    .photo {
      width: 100vw;
      position: absolute;
      top: 15vw;
      object-fit: cover;
    }

    .take-photo-countdown-container {
      width: 100vw;
      position: absolute;
      top: 15vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .countdown {
        font-family: var(--font-family-primary);
        font-weight: 600;
        font-size: 20vw;
        line-height: 20vw;
        color: #2ED3CE;
      }
    }


    .clip-rectangle {
      -webkit-mask:
        /*                        X    Y   / wwidth height */
        linear-gradient(#000 0 0) 10vw 15vw/80vw 113vw,
        /* control the clipped part here*/
        linear-gradient(rgba(255, 255, 255, 0.5) 0 0);
      /* control the opacity here */
      -webkit-mask-repeat: no-repeat;
    }

    .rectangle {
      position: absolute;
      background: linear-gradient(rgba(0, 0, 0, 0.4) 0 0);
    }

    .rectangle-1 {
      width: 100vw;
      height: 3vw;
      top: 40vw;
    }

    .rectangle-2 {
      width: 3vw;
      top: 43vw;
    }

    .rectangle-3 {
      width: 100vw;
      height: 13vw;
      bottom: 0vw;
    }

    .rectangle-4 {
      width: 3vw;
      top: 43vw;
      right: 0;
    }

    // .take-photo-title {
    //     font-family: var(--font-family-header);
    //     width: 100vw;
    //     text-align: center;
    //     font-size: 8.5vw;
    //     color: #ffffff;
    //     font-weight: 400;
    //     padding: 0vw 0 0vw 0;
    //     margin: 0; 
    //     position: fixed;
    //     top: 17vw;
    // }

    .take-photo-subtitle {
      width: 100vw;
      font-family: 'ClanPro';
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      margin: 0;
      text-align: center;
      display: none;
    }

    .take-photo-overlay-box {
      width: 10.4vw;
      height: 10.4vw;
      border: 3px #ffffff solid;
      box-sizing: border-box;
      position: absolute;
    }

    .take-photo-left-top {
      top: 43vw;
      left: 3vw;
      border-right: 0px;
      border-bottom: 0px;
    }

    .take-photo-right-top {
      top: 43vw;
      right: 3vw;
      border-left: 0px;
      border-bottom: 0px;
    }

    .take-photo-left-bottom {
      bottom: 13vw;
      left: 3vw;
      border-right: 0px;
      border-top: 0px;
    }

    .take-photo-right-bottom {
      bottom: 13vw;
      right: 3vw;
      border-left: 0px;
      border-top: 0px;
    }

    .take-photo-button {
      display: none;
      position: absolute;
      top: 45%;
      left: 20%;
      width: 60vw;
      height: 12vw;
      border: 2px solid #FFFFFF;
      box-sizing: border-box;
      background-color: transparent;
      font-family: var(--font-family-button);
      font-weight: 500;
      font-size: 3.5vw;
      color: #ffffff;
    }

    .take-photo-circle-button-container {
      display: flex;
      position: absolute;
      bottom: 17vw;
      left: 40vw;
      justify-content: center;
      align-items: center;
      border: 2px solid #ffffff;
      width: 20vw;
      height: 20vw;
      border-radius: 20vw;
      box-sizing: border-box;

      .take-photo-circle-button {
        width: 16vw;
        height: 16vw;
        border-radius: 16vw;
        background-color: #ffffff;
        // outline-style: solid;
        // outline-color: #ffffff;
        // outline-width: 2px;
        // outline-offset: 6px;
        // box-shadow: 0px 0px 0px 5px #fff inset;

      }
    }

  }
}