.iframe-desktop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-primary);

    .iframe-desktop {
        width: 43vh; 
        height: 84vh;
        background-color: #ffffff;
        // border: 2px solid var(--color-secondary);
    }
}