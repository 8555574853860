@import "./styles/designConfig/scssVariables.scss";
// @import './styles/designConfig/cssVariables.scss';

@import "./styles/defaultVariables.scss";

// @import '../public/brand/cssVariables.scss';
// @import '../public/brand/cssOverride.scss';

@import "brand/cssVariables.scss";
@import "brand/cssOverride.scss";

//fonts
@import "./styles/fonts/mazin.scss";
@import "./styles/fonts/gotham.scss";
@import "./styles/fonts/merriweather.scss";
@import "./styles/fonts/nunito-sans.scss";

.app-container {
  height: 100%;
  .home-container,
  .session-url-container,
  .edit-photo-container,
  .final-photo-container,
  .loading-screen-container,
  .home-extended-container,
  .terms-container,
  .take-photo-container,
  .select-favorite-photo-container,
  .photo-detail-container,
  .souvenir-ticket-container,
  .shipping-information-container,
  .congratulations-container,
  .no-matches-container,
  .error-container,
  .home-preview-container,
  .landing-menu-container {
    .button-square {
      border-radius: 0 !important;
    }

    .button-rounded-corner {
      // width: 53.33vw  !important;
      height: 13.33vw !important;
      border-radius: 1vw !important;
      font-family: "urw-din" !important;
      font-family: var(--font-family-primary) !important;
      font-size: 4vw !important;
      font-weight: 600;
      text-transform: uppercase !important;
      border: 2px solid #fff !important;
      box-sizing: border-box !important;
      box-shadow: 0 4px 4px rgba (0, 0, 0, 0.25) !important;
      padding: 0 !important;
    }

    .button-pillow {
      text-transform: none ;
      border-radius: 27vw;
      // width: 50vw !important;
      height: 13.3vw;
    }

    .button-slim-rounded {
      border-radius: 1.6vw !important;
      box-shadow: 4px 4px 4px rgba(26, 36, 67, 0.1);
    }
  }
}

.primary-selected:active,
.secondary-selected:active {
  background-color: #990000;
  color: #ffffff;
}

.app-rotated {
  background-color: #828282;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .phone-rotated {
  }

  .rotate-text {
    font-family: var(--font-family-secondary);
    color: #ffffff;
    font-size: 2vw;
    text-align: center;
    margin-top: 2vw;
    font-weight: 700;
  }
}

.app-desktop {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #c81414;
}

@media (min-aspect-ratio: 10/9) {
  .app-container {
    display: flex;
  }
  .app-rotated {
    display: flex;
  }
}

html {
  // background-color: var(--color-primary);
}
