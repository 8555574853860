.home-extended-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 28vw;
    padding-bottom: 11.5vw;
    align-items: center;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;      

    .logo-header-container {
        height: 12vw;
    }  

    .pb-footer {
        background-color: var(--color-primary);

        .legal {
            color: #ffffff;
        }
    }
}

.extended__promotional-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0vw;
    margin-bottom: auto;
}

.extended__promotional-content-container {
    width: 100vw;
    height: 79vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
    padding-top: 2vw;
    justify-content: flex-start;
    position: absolute;
    // background-color: red;

    .extended__title-container {
        height: 14vw;
        width: 90vw;

        .extended__title-img {
            height: 14vw;
            width: 90vw;
        }
    }

    .extended__video-container {
        width: 81.86vw; 
        height: 81.86vw;
        border-radius: 2.93vw;
        margin-top: 3vw;

        .extended__video {
            width: 81.86vw; 
            height: 81.86vw;
            border-radius: 2.93vw;
            border: 0.25px solid #00325B;
            box-sizing: border-box;
        }
    }







    .extended__start-button {
        width: 63.3vw;
        height: 13.3vw;
        background-color: var(--color-tertiary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 1vw;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
        position: fixed;
        bottom: 15vw;
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 3.8vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 1px;
        text-transform: uppercase;
        // margin: 0px;
        // padding: 0px;
    }

    .extended__start-button:active {
        background-color: var(--color-secondary);
        color: var(--color-tertiary);
    }

    .extended__start-button-text {
        font-family: var(--font-family-secondary);
        font-size: 4.8vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 1.2px;
        text-transform: capitalize;
        margin: 0px;
        padding: 0px;
    }

}


@media (min-aspect-ratio: 9/15) {
    .home-extended-container{
        padding-top: 14vw;    
    
        .logo-header-container {
            height: 12vw;
        }  

    }

    .extended__promotional-content-container {
        padding-top: 1vw;

        .extended__start-button-text {
            color: var(--color-primary);
            font-family: var(--font-family-secondary);
            font-size: 4.8vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 1.2px;
            text-transform: capitalize;
            margin: 0px;
            padding: 0px;
        }

        .extended__promotional-container {
            margin-top: 6.6vw;
        }

        .extended__title-container {
            height: 14vw;
            width: 80vw;
    
            .extended__title-img {
                height: 14vw;
                width: 80vw;
            }
        }

        .extended__video-container {
            width: 80vw; 
            height: 80vw;
            border-radius: 2.93vw;
            margin-top: 0vw;
    
            .extended__video {
                width: 80vw; 
                height: 80vw;
                border-radius: 2.93vw;
                border: 0.25px solid #00325B;
                box-sizing: border-box;
            }
        }
    }



}

@media (min-aspect-ratio: 10/9) {
    .home-extended-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 9vw;
        padding-bottom: 11vw;
        align-items: center;
    
        .extended__promotional-content-container {
            height: auto;



            .extended__promotional-container {

                .extended__promotional-group {

                    .extended__fun-line-container {

                        .extended__promotional-text {

                        }
                        
                        .extended__title-bold  {

                        }

                        .extended__fun-line {

                        }

                        .extended__line {

                        }

                        .extended__promotional-text {

                        }

                        .extended__fun-line-container {

                            .extended__subtitle {

                            }
                        }
                    }
                }

            }

            .extended__start-button {
                width: 30vw;
                height: 5vw;
                margin-top: 0vw;
                position: fixed;
                bottom: 3vw;
                font-size: 2vw;
            }
        }

    }
}