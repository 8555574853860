.accept-cookies-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 202;
}

.accept-cookies-container {
    width: 100vw;
    min-height: 100%;
    padding-bottom: 5vw;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: transparent;

    .close-modal-container {
        width: 100%;
        justify-content: flex-end;
        display: flex;

        .close-modal-x {
            cursor: pointer;
            width: 3.2vw;
        }
    }

    .rydercup__promotional-text-2 {
        font-family: var(--font-family-secondary);
        font-size: 3.8vw;
        text-align: center;
    }

    .photo-container {
        position: relative;
        left: 6vw;
    }

    .footer-modifier {
        .pb-footer {
            // left: 0;
            position: relative;
            top: 9vw;
        }
    }

    .team-modal-content {
        width: 100vw;
        min-height: 62vw;
        color: #ffffff;
        background: rgba(0,0,0,0.9);
        padding: 5vw 5vw 5vw 5vw;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0vw;

        .menu-close {
            width: 6.4vw;
            height: 6.4vw;
            position: absolute;
            margin-left: -5vw;
        }


        .register-title {
            font-family: var(--font-family-terciary);
            text-align: center;
            font-weight: 700;
            color: var(--color-black);
            font-size: 4.8vw;
            line-height: 6vw;
            padding-top: 2vw;
            margin: 0;
            letter-spacing: 1%;
        }

        .secondary-register-title {
            font-family: var(--font-family-secondary);
            font-size: 3.8vw;
            font-weight: 400;
            padding: 2.4vh 0px;
        }

        .form-container {
            padding-top: 6vw;

            .input-container {
                width: 83vw;
                margin-top: 4.2vw;

                .input-label {
                    font-family: var(--font-family-secondary);
                    font-size: 3.2vw;
                    line-height: 3.2vw;
                    color: #ffffff;

                    .required {
                        color: #ffffff;
                    }
                }

                .input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                        
                    .input-firstname {
                        width: 77.3vw;
                        height: 11.2vw;
                        padding: 0 vw;
                        box-sizing: border-box;
                        font-size: 3.8vw;
                        letter-spacing: 1%;
                        font-family: var(--font-family-secondary);
                        color: #000000;
                        border-radius: 4px;
                        background-color: #ffffff;
                    }

                    .input-wrong-state {
                        border-color: var(--color-primary);
                        border-width: 2px;
                        border-style: solid;
                    }
                }

            }
        }

        .consent-container {
            display: flex;
            flex-direction: row;
            padding: 3vw 0;
            width: 79vw;

            .consent-check-container {
                padding-right: 1.5vw;

                .consent-check {
                    fill: #DCB400;
                    stroke: #DCB400;
                }
            }

            .agree-text {
                font-family: var(--font-family-secondary);
                font-size: 2.14vw;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                color: #ffffff;

                .agree-text-link {
                    font-size: 2.14vw;
                    text-decoration: underline;
                    font-weight: 700;
                    color: #DCB400;
                }
            }
        }

        .fantastic-photos-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 10vh;
            align-items: center;
        }

        .submit-button {
            width: 45.33vw;
            height: 10.66vw;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px;
            margin-left: auto;
            margin-right: auto;
            text-transform: uppercase;

            .submit-button-text {
                font-family: var(--font-family-secondary);
                font-size: 3.2vw;
                margin: 0;
                padding: 0;
                color: var(--color-black);
                font-weight: 700;
            }
        }

        .submit-button-available {
            background-color: #ffffff;
        }

        .primary-team-button {
            background-color: var(--color-primary);
            margin-top: 3.5vh;
            margin-bottom: 3.5vh;
        }

        .secondary-team-button {
            background-color: var(--color-dark-blue);
        }

        .default-team-button {
            background-color: var(--color-gray);
        }

    }
}