.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(22, 22, 22, 0.6);
    z-index: 202;
}

.fantastic-photo-container {
    width: 100vw;
    padding-bottom: 5vw;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: transparent;

    .close-modal-container {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        padding: 1vw 0vw;
        padding-top: 0;

        .close-modal-x {
            cursor: pointer;
        }
    }

    .rydercup__promotional-text-2 {
        font-family: var(--font-family-secondary);
        font-size: 4.8vw;
        text-align: center;
    }

    .photo-container {
        position: relative;
        left: 6vw;
    }

    .footer-modifier {
        .pb-footer {
            // left: 0;
            position: relative;
            top: 9vw;
        }
    }

    .team-modal-content {
        width: 100vw;
        background: var(--color-secondary);
        padding: 5vw 5vw 5vw 5vw;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        bottom: 4vw;

        .menu-close {
            width: 6.4vw;
            height: 6.4vw;
            position: absolute;
            margin-left: -5vw;
        }


        .register-title {
            font-family: var(--font-family-terciary);
            text-align: center;
            font-weight: 700;
            color: var(--color-black);
            font-size: 4.8vw;
            line-height: 6vw;
            padding-top: 2vw;
            margin: 0;
            letter-spacing: 1%;
        }

        .secondary-register-title {
            font-family: var(--font-family-secondary);
            font-size: 3.8vw;
            font-weight: 400;
            padding: 2.4vh 0px;
        }

        .form-container {
            padding-top: 6vw;

            .input-container {
                width: 83vw;
                margin-top: 4.2vw;

                .input-label {
                    font-family: var(--font-family-secondary);
                    font-size: 3.2vw;
                    line-height: 3.2vw;
                    color: #ffffff;

                    .required {
                        color: #ffffff;
                    }
                }

                .input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                        
                    .input-firstname {
                        width: 77.3vw;
                        height: 11.2vw;
                        padding: 0 vw;
                        box-sizing: border-box;
                        font-size: 3.8vw;
                        letter-spacing: 1%;
                        font-family: var(--font-family-secondary);
                        color: #000000;
                        border-radius: 4px;
                        background-color: #ffffff;
                    }

                    .input-wrong-state {
                        border-color: var(--color-primary);
                        border-width: 2px;
                        border-style: solid;
                    }
                }

            }
        }

        .consent-container {
            display: flex;
            flex-direction: row;
            padding: 3vw 0;
            width: 79vw;

            .consent-check-container {
                padding-right: 1.5vw;

                .consent-check {
                    fill: #DCB400;
                    stroke: #DCB400;
                }
            }

            .agree-text {
                font-family: var(--font-family-secondary);
                font-size: 2.14vw;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                color: #ffffff;

                .agree-text-link {
                    font-size: 2.14vw;
                    text-decoration: underline;
                    font-weight: 700;
                    color: #DCB400;
                }
            }
        }

        .fantastic-photos-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .rydercup__photo-modal-container {
                margin: 3vw 0;
                width: 100vw;
                align-items: center;
                justify-content: center;
                display: flex;
        
                .rydercup__photo {
                    width: 55vw;
                    margin-left: 12vw;
                }
            }
        }

        .submit-button {
            width: 69.33vw;
            height: 12vw;
            background-color: #BDBDBD;
            border-radius: 5.86vw;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-top: auto;
            margin-left: auto;
            margin-right: auto;

            .submit-button-text {
                font-family: var(--font-family-secondary);
                font-size: 3.8vw;
                margin: 0;
                padding: 0;
                color: var(--color-secondary);
                font-weight: 700;
            }
        }

        .submit-button-available {
            background-color: #ffffff;
        }

        .primary-team-button {
            background-color: var(--color-primary);
            margin-top: 3.5vh;
            margin-bottom: 3.5vh;
        }

        .secondary-team-button {
            background-color: var(--color-dark-blue);
        }

        .default-team-button {
            background-color: var(--color-gray);
        }

    }
}

.rydercup__promotional-content-container {
    width: 100vw;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
    padding-top: 18vw;
    justify-content: flex-start;
    position: absolute;
    // background-color: red;

    .rydercup__promotional-text {
        font-family: Gotham;
        font-size: 5.3vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: 1%;
        text-align: center;
        color: var(--color-black);
        margin: 0;
        padding: 0;

        .rydercup__new-england-title-bold {
            font-weight: 800;
            color: var(--color-secondary);
        }
    }

    .rydercup__promotional-text-2 {
        font-family: 'Nunito Sans';
        text-align: center;
        font-size: 4.8vw;
        margin: 3.2vw;
    }

    .rydercup__training-camp {
        font-family: Mazin;
        font-size: 4vw;
        font-weight: 500;
        color: var(--color-secondary);
        text-align: center;
        margin: 2vw 0 0 0;
        letter-spacing: 4px;
    }

    .rydercup__line {
        width: 25.6vw;
        height: 2px;
        background-color: var(--color-tertiary);
        margin: 4.2vw 0;
    }

    .rydercup__camp-logo {
        width: 15vw;
        height: 19.2;
    }

    .rydercup__fantastic-photos {
        width: 72vw;
    }

    .rydercup__photo-modal-container {
        margin: 2.8vw 0;
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-left: 12vw;

        .rydercup__photo {
            width: 55vw;
            margin-left: 12vw;
        }
    }

    .rydercup__fun-line {
        margin: 0;
        color: var(--color-secondary);
        text-transform: uppercase;
        font-family: Mazin;
        font-weight: 400;
        font-size: 3.8vw;
        letter-spacing: 2px;

        .rydercup__fun-line-fan {
            font-size: 4.8vw;
            font-weight: 800;
        }
    }

    .rydercup__fantastic-photo-svg {
    }

    .rydercup__strip-merged-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // background-color: cadetblue;
        padding-bottom: 42vw;


        .rydercup__strip-merged {
            width: 74vw;
            margin: 0vw 0vw;
        }
    }

    .rydercup__loading-smile {
        width: 65vw;
    }

    .rydercup__start-button {
        width: 35vw;
        height: 14vw;
        background-color: var(--color-primary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
    }

    .rydercup__start-button-text {
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 5.7vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }
}
@media (min-aspect-ratio: 9/15) {
    .rydercup__promotional-content-container {
        padding-top: 1vw;

        .rydercup__promotional-text {
            font-family: Mazin;
            font-size: 4.5vw;
            font-weight: 400;
    
            .rydercup__new-england-title-bold {
                font-weight: 800;
                color: var(--color-secondary);
            }
        }
        
        .rydercup__training-camp {
            font-family: Mazin;
            font-size: 3.6vw;
            margin: 0.7vw 0 0 0;
        }

        .rydercup__line {
            margin: 2.5vw 0;
        }
    
        .rydercup__camp-logo {
            width: 12vw;
        }

        .rydercup__photo-modal-container {
            margin: 3vw 0;
            width: 100vw;
            align-items: center;
            justify-content: center;
            display: flex;
            padding-left: 12vw;
    
            .rydercup__photo {
                width: 55vw;
                margin-left: 12vw;
            }
        }

        .rydercup__fantastic-photos {
            width: 62vw;
        }

        .rydercup__fun-line {
            margin: 0;
            color: var(--color-secondary);
            text-transform: uppercase;
            font-family: Mazin;
            font-weight: 400;
            font-size: 3.2vw;
            letter-spacing: 2px;
    
            .rydercup__fun-line-fan {
                font-size: 4.3vw;
                font-weight: 800;
            }
        }

        .rydercup__strip-merged-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            // background-color: cadetblue;
            padding-bottom: 42vw;


            .rydercup__strip-merged {
                width: 86vw;
                margin: 0vw 0vw;
            }
        }

        .rydercup__loading-smile {
            width: 65vw;
        }

        .rydercup__start-button {
            width: 35vw;
            height: 14vw;
            background-color: var(--color-primary);
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin-top: 5vw;
            text-decoration: none;
        }

        .rydercup__start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 5.7vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: 1.2px;
        }
    }
}