.souvenir-ticket-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url('/assets/images/nasdaq-background.png');
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    padding-top: 30vw;
    box-sizing: border-box;
    align-items: center;

    .souvenir-ticket-title {
        font-family: var(--font-family-primary);
        width: 80vw;
        text-align: center;
        font-size: 4.8vw;
        line-height: 5.33vw;
        color: var(--color-secondary);
        font-weight: 900;
        padding: 0vw 0 1vw 0;
        margin: 0; 
        text-transform: uppercase;
    }

    .souvenir-ticket-photomontage-container {
        width: 42vw;
        height: 105vw;
        position: relative;
        border: 1px solid #cccccc;

        .souvenir-ticket-image {
            width: inherit;
            height: 75vw;
            object-fit: cover;
            position: absolute;
            top: 22vw;
        }

        .souvenir-ticket-template {
            width: inherit;
            object-fit: cover;
            position: absolute;
        }
    }


    .souvenir-ticket-buttons-container {
        width: 100vw;
        display: flex;
        position: fixed;
        bottom: 15vw;
        align-items: center;
        justify-content: space-between;
        padding: 0 5vw 0 5vw;
        box-sizing: border-box;

        .souvenir-ticket-decline-button {
            width: 36.8vw;
            height: 10.4vw;
            border: 2px solid var(--color-secondary);
            border-radius: 1vw;
            box-sizing: border-box;
            background-color: transparent;
            font-family: var(--font-family-button);
            font-weight: 600;
            font-size: 4vw;
            color: var(--color-secondary);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 0;
        }

        .souvenir-ticket-agree-button {
            width: 26.13vw;
            height: 10.4vw;
            border: 2px solid var(--color-primary);
            box-sizing: border-box;
            border-radius: 1vw;
            background-color: var(--color-secondary);
            font-family: var(--font-family-button);
            font-weight: 600;
            font-size: 4vw;
            color: var(--color-primary);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 0;
        }

    }

    .souvenir-ticket-buttons-centered {
        justify-content: center;
    }

}

@media (min-aspect-ratio: 9/15) {
    .souvenir-ticket-container {
        padding-top: 22vw;
    
        .souvenir-ticket-title {
            font-size: 4vw;
            line-height: 4.8vw;
        }
    
        .souvenir-ticket-photomontage-container {
            width: 34vw;
            height: 85vw;
            margin-top: 4vw;
    
            .souvenir-ticket-image {
                height: 62vw;
                top: 17vw;
            }
    
            .souvenir-ticket-template {
            }
        }
    
    
        .souvenir-ticket-buttons-container {
    
            .souvenir-ticket-decline-button {
            }
    
            .souvenir-ticket-agree-button {
            }
    
        }
    
        .souvenir-ticket-buttons-centered {
        }
    
    }
}