.error-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // background-image: url('/asserror/nasdaq-background.png');
  background-size: cover;
  /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 30vw;
  box-sizing: border-box;
  align-items: center;

  .error-title {
    width: 60vw;
    font-family: var(--font-family-primary);
    text-align: center;
    font-size: 4.8vw;
    line-height: 5.8vw;
    color: var(--color-secondary);
    font-weight: 900;
    padding: 1vw 0 0vw 0;
    margin: 0;
  }

  .error-text {
    width: 50vw;
    font-family: var(--font-family-primary);
    text-align: center;
    font-size: 4.2vw;
    line-height: 4.4vw;
    color: var(--color-secondary);
    font-weight: 500;
    margin: 0;
    padding: 0vw 0 4vw 0;
  }

  .error-image {
    width: 55vw;
    height: 96vw;
    object-fit: cover;
    border: 0.25px solid var(--color-secondary);
    box-sizing: border-box;
    border-radius: 2.9vw;
  }

  .error-buttons-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5vw 0 5vw;
    box-sizing: border-box;
    margin-top: auto;
    margin-bottom: auto;
    position: fixed;
    bottom: 15vw;


    .error-decline-button {
      width: 29.86vw;
      height: 8.8vw;
      border: 2px solid var(--color-secondary);
      border-radius: 26.6vw;
      box-sizing: border-box;
      background-color: transparent;
      font-family: var(--font-family-button);
      font-weight: 900;
      font-size: 3.2vw;
      color: var(--color-secondary);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


    .error-try-again-button {
      min-width: 39.2vw;
      height: 10.4vw;
      border: 2px solid var(--color-primary);
      box-sizing: border-box;
      border-radius: 1vw;
      background-color: var(--color-secondary);
      font-family: var(--font-family-button);
      font-weight: 600;
      font-size: 3.8vw;
      color: var(--color-primary);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 2vw;
    }

  }

}

@media (min-aspect-ratio: 9/15) {
  .error-container {
    padding-top: 20vw;

    .error-title {
      width: 60vw;
      font-size: 4vw;
      line-height: 4.8vw;
    }

    .error-text {
      width: 40vw;
      font-size: 3.7vw;
      line-height: 4vw;
    }

    .error-image {
      width: 45vw;
      height: 80vw;
    }

    .error-buttons-container {


      .error-decline-button {}


      .error-try-again-button {}

    }

  }
}