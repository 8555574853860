.logo-header-container {
  position: absolute;
  padding-top: 3vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100vw;
  height: 14.5vw;
  background-color: var(--color-white);
  color: var(--color-green);
  z-index: 1;

  .logo {
    display: flex;
    width: 22.4vw;
  }

  .home {
    position: absolute;
    left: 7vw;
  }

  .close {
    width: 7vw;
    position: absolute;
    right: 7vw;
  }

  .letter {
    width: 6.5vw;
    position: absolute;
    right: 13vw;
  }

  .download {
    width: 4vw;
    position: absolute;
    right: 6vw;
  }

  .save {
    width: 17vw;
    height: 8.5vw;
    background-color: var(--color-primary);
    border-radius: 2px;
    position: absolute;
    right: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-save {
      font-size: 3.3vw;
      font-family: SFProDisplay, Arial;
      color: var(--color-secondary);
    }

  }

  .text-save:active {
    color: #990000;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .header-back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    // z-index: 200;
    text-transform: uppercase;
    font-family: var(--font-family-primary);
    font-weight: 700;
    color: var(--color-primary);
    width: 65px;
    left: 10px;
    justify-content: space-between;
    top: 4vw;
    font-size: 3.8vw;
    fill: var(--color-primary);
    stroke: var(--color-primary);

    .header-back-arrow {
      width: 2.5vw;
    }
  }

  .header-back-button:active {
    color: #990000;
  }

  .header-back-arrow:active {
    color: #990000;
  }

  .header-save {
    display: flex;
    position: absolute;
    // z-index: 200;
    text-transform: uppercase;
    font-family: var(--font-family-primary);
    font-weight: 700;
    color: var(--color-primary);
    top: 4vw;
    right: 7.73vw;
    font-size: 3.8vw;
  }

  .contrast-text {
    color: var(--color-secondary);
    fill: var(--color-secondary);
    stroke: var(--color-secondary);
  }

  .header-save:active {
    color: #990000;
  }

  .pressed-save {
    color: #990000;
  }

  .flag {
    height: 3.46vw;
    position: absolute;
    top: 6vw;
  }

  .french-flag {
    right: 3vw;
  }

  .usa-flag {
    right: 10.46vw;
  }

  .selected-flag {
    outline: solid 3px var(--color-tertiary);
  }

}

.dark-header {
  background-color: var(--color-primary);
  // background-color: red;
}

.fixed-header {
  position: fixed;
}

@media (min-aspect-ratio: 10/9) {
  .logo-header-container {
    padding-top: 0vw;
    height: 8vw;
    background-color: transparent;

    .logo {
      display: flex;
      width: 15vw;
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
    }

    .centered-logo {
      top: unset;
      left: unset;
      position: relative;
      margin-bottom: auto;
      margin-top: auto;
    }

    .header-back-button {
      width: unset;
      top: 2vw;
      font-size: 1.5vw;

      .header-back-arrow {
        width: 1vw;
        padding: 0 0.5vw 0 0;
      }
    }

    .header-save {
      display: flex;
      top: 2vw;
      font-weight: 700;
      color: var(--color-primary);
      right: 1vw;
      font-size: 1.5vw;
    }
  }
}