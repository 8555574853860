.caption-frame-container {
  width: 1080px;
  height: 1080px;
  position: fixed;
  left: 100%;
  top: 100%;

  .content {
    width: 100%;
    height: 100%;
    position: relative;

    .caption-frame-text {
      position: absolute;
    }
  }
}

.caption-frame-scaled {
  width: 100%;
  height: 100%;
  position: absolute;
  left: unset;
  top: unset;

  .caption-frame-text-container {
    width: 0px;
    height: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .caption-frame-text {
      text-align: center;
      vertical-align: text-top;
      white-space: nowrap;
    }
  }

}