.rydercup__promotional-content-container {
    width: 100vw;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
    padding-top: 9vw;
    justify-content: flex-start;
    position: absolute;
    // background-color: red;

    .rydercup__promotional-text {
        font-family: Gotham;
        font-size: 5.3vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: 1%;
        text-align: center;
        color: var(--color-black);
        margin: 0;
        padding: 0;

        .rydercup__new-england-title-bold {
            font-weight: 800;
            color: var(--color-secondary);
        }
    }

    .rydercup__promotional-text-2 {
        font-family: 'Nunito Sans';
        text-align: center;
        font-size: 4.8vw;
        margin: 3.2vw;
    }

    .rydercup__training-camp {
        font-family: Mazin;
        font-size: 4vw;
        font-weight: 500;
        color: var(--color-secondary);
        text-align: center;
        margin: 2vw 0 0 0;
        letter-spacing: 4px;
    }

    .rydercup__line {
        width: 25.6vw;
        height: 2px;
        background-color: var(--color-tertiary);
        margin: 4.2vw 0;
    }

    .rydercup__camp-logo {
        width: 15vw;
        height: 19.2;
    }

    .rydercup__fantastic-photos {
        width: 72vw;
    }

    .rydercup__photo-container {
        margin: 2.8vw 0;
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;

        .rydercup__photo {
            width: 72vw;
            margin-left: 12vw;
        }
    }

    .rydercup__fun-line {
        margin: 0;
        color: var(--color-secondary);
        text-transform: uppercase;
        font-family: Mazin;
        font-weight: 400;
        font-size: 3.8vw;
        letter-spacing: 2px;

        .rydercup__fun-line-fan {
            font-size: 4.8vw;
            font-weight: 800;
        }
    }

    .rydercup__fantastic-photo-svg {
    }

    .rydercup__strip-merged-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // background-color: cadetblue;
        padding-bottom: 42vw;


        .rydercup__strip-merged {
            width: 74vw;
            margin: 0vw 0vw;
        }
    }

    .rydercup__loading-smile {
        width: 65vw;
    }

    .rydercup__start-button {
        width: 35vw;
        height: 14vw;
        background-color: var(--color-primary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
    }

    .rydercup__start-button-text {
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 5.7vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }
}
@media (min-aspect-ratio: 9/15) {
    .rydercup__promotional-content-container {
        padding-top: 6vw;

        .rydercup__promotional-text {
            font-family: Mazin;
            font-size: 4.5vw;
            font-weight: 400;
    
            .rydercup__new-england-title-bold {
                font-weight: 800;
                color: var(--color-secondary);
            }
        }
        
        .rydercup__training-camp {
            font-family: Mazin;
            font-size: 3.6vw;
            margin: 0.7vw 0 0 0;
        }

        .rydercup__line {
            margin: 2.5vw 0;
        }
    
        .rydercup__camp-logo {
            width: 12vw;
        }

        .rydercup__photo-container {
            margin: 3vw 0;
            width: 100vw;
            align-items: center;
            justify-content: center;
            display: flex;
    
            .rydercup__photo {
                width: 55vw;
            }
        }

        .rydercup__fantastic-photos {
            width: 62vw;
        }

        .rydercup__fun-line {
            margin: 0;
            color: var(--color-secondary);
            text-transform: uppercase;
            font-family: Mazin;
            font-weight: 400;
            font-size: 3.2vw;
            letter-spacing: 2px;
    
            .rydercup__fun-line-fan {
                font-size: 4.3vw;
                font-weight: 800;
            }
        }

        .rydercup__strip-merged-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            // background-color: cadetblue;
            padding-bottom: 42vw;


            .rydercup__strip-merged {
                width: 86vw;
                margin: 0vw 0vw;
            }
        }

        .rydercup__loading-smile {
            width: 65vw;
        }

        .rydercup__start-button {
            width: 35vw;
            height: 14vw;
            background-color: var(--color-primary);
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin-top: 5vw;
            text-decoration: none;
        }

        .rydercup__start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 5.7vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: 1.2px;
        }
    }
}