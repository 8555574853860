  .canvas {
    position: absolute;
    top: 0vw;
    left: 0;
    z-index: -1;
    display: none;
  }

  .final-video-title {
    font-family: var(--font-family-primary);
    width: 100%;
    text-align: center;
    font-size: 5.33vw;
    line-height: 6.66vw;
    color: #ffffff;
    font-weight: 400;
    padding: 6.93vw 0 6.93vw 0;
    margin: 0;
  }

  .final-video-video-container {
    width: 45vw;
    height: 80vw;
    background-color: transparent;
    border-radius: 2.9vw;

    .final-video-video {
      position: absolute;
      width: 45vw;
      height: 80vw;
      object-fit: cover;
    }

    .final-video-ugc-container {
      position: relative;
      width: 45vw;
      height: 80vw;
      // background-color: aqua;

      .final-video-ugc {
        position: absolute;
        width: 45vw;
        height: 80vw;
        // object-fit: cover;
      }
    }

  }

  .final-video-image {
    width: 45vw;
    // height: 96vw;
    object-fit: cover;
    border: 0.25px solid var(--color-secondary);
    box-sizing: border-box;
    border-radius: 2.9vw;
  }

  .final-video-buttons-container {
    width: 100vw;
    display: flex;
    position: fixed;
    bottom: 15vw;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw 0 5vw;
    box-sizing: border-box;

    .final-video-decline-button {
      min-width: 32.26vw;
      height: 10.4vw;
      border: 2px solid var(--color-tertiary);
      border-radius: 1vw;
      box-sizing: border-box;
      background-color: transparent;
      font-family: var(--font-family-button);
      font-weight: 700;
      font-size: 5vw;
      color: var(--color-secondary);
      padding: 0 2vw;
    }

    .final-video-agree-button {
      width: 52vw;
      height: 10.4vw;
      border: 2px solid var(--color-primary);
      box-sizing: border-box;
      border-radius: 1vw;
      background-color: var(--color-secondary);
      font-family: var(--font-family-button);
      font-weight: 700;
      font-size: 5vw;
      color: var(--color-primary);
      padding: 0;
    }

  }

  .final-video-buttons-container-centered {
    justify-content: center;
  }


  @media (min-aspect-ratio: 9/15) {
    .final-video-container {
      padding-top: 22vw;


      .final-video-title {}

      .final-video-image {
        width: 53vw;
      }

      .final-video-buttons-container {


        .final-video-decline-button {}

        .final-video-agree-button {}

      }

    }
  }