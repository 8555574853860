.pb-footer {
    width: 100vw;
    height: 9.6vw;
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pb-footer);
    // z-index: 201 !important;



    .logo {
        width: 4vw;
        height: 6.5vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .pb-footer-svg {
        width: 28.26vw;
        height: 6.13vw;
        margin-top: -1vw;
    }

    .pb-text-black {
        font-size: 3.5vw;
        font-family: SFProDisplay, Arial;
        font-weight: bold;
        color: black;
    }

    .legal {
        position: absolute;
        left: 3vw;
        font-size: 2.8vw;
        line-height: 3vw;
        font-family: var(--font-family-primary);
        font-weight: 500;
        color: var(--color-dark-gray);
        padding: 0px;
        margin: 0px;
    }

    .privacy {
        position: absolute;
        right: 3vw;
        font-size: 2.8vw;
        line-height: 3vw;
        font-family: var(--font-family-secondary);
        font-weight: 500;
        color: var(--color-dark-gray);
        padding: 0px;
        margin: 0px;
    }

    .terms {
        position: absolute;
        left: 3vw;
        font-size: 2.8vw;
        line-height: 3vw;
        font-family: var(--font-family-secondary);
        font-weight: 500;
        color: var(--color-dark-gray);
        padding: 0px;
        margin: 0px;
    }

    .light-text {
        color: var(--color-quaternary);
    }

    .legal-left {
        left: 3vw;
    }
    
    .help {
        position: absolute;
        right: 3vw;
        font-size: 2.8vw;
        line-height: 3vw;
        font-family: var(--font-family-primary);
        font-weight: 500;
        color: var(--color-dark-gray);
        padding: 0px;
        margin: 0px;
        text-decoration: none;
    }

}

.dark-footer {
    background-color: var(--color-primary);
    border-top: solid 1px var(--color-primary);
}

@media (min-aspect-ratio: 10/9) {
    .pb-footer {
        width: 100vw;
        height: 3vw;

        .pb-footer-svg {
            width: 12vw;
            height: 7vw;
            margin-top: 0vw;
        }

        .privacy {
            font-size: 1vw;
        }
    
        .terms {
            font-size: 1vw;
        }
    }
}