.final-video-montage-container {
  width: 1080px;
  height: 1080px;
  position: fixed;
  top: 100%;
  left: 100%;

  .final-video-montage-video-container {
    width: 100%;
    height: 100%;
    position: relative;

    .final-video-montage-video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .final-video-montage-ugc-container {
      position: relative;
      width: 100%;
      height: 100%;
      // background-color: aqua;

      .final-video-montage-ugc {
        position: absolute;
        // object-fit: cover;
      }
    }

  }
}