.congratulations-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url('/assets/images/nasdaq-background.png');
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    padding-top: 30vw;
    box-sizing: border-box;
    align-items: center;

    .congratulations-title {
        font-family: var(--font-family-primary);
        width: 80vw;
        text-align: center;
        font-size: 4.8vw;
        line-height: 5.33vw;
        color: var(--color-secondary);
        font-weight: 900;
        padding: 0vw 0 0vw 0;
        margin: 0; 
        text-transform: uppercase;
    }

    .congratulations-subtitle {
        font-family: var(--font-family-primary);
        width: 60vw;
        text-align: center;
        font-size: 4.8vw;
        line-height: 5.33vw;
        color: var(--color-secondary);
        font-weight: 400;
        padding: 4.8vw 0 4.8vw 0;
        margin: 0; 
    }

    .congratulations-photomontage-container {
        width: 35vw;
        height: 87.5vw;
        position: relative;
        border: 1px solid #cccccc;

        .congratulations-image {
            width: inherit;
            height: 63vw;
            object-fit: cover;
            position: absolute;
            top: 18vw;
        }

        .congratulations-template {
            width: inherit;
            object-fit: cover;
            position: absolute;
        }
    }


    .congratulations-buttons-container {
        width: 100vw;
        display: flex;
        position: fixed;
        bottom: 15vw;
        align-items: center;
        justify-content: space-between;
        padding: 0 5vw 0 5vw;
        box-sizing: border-box;

        .congratulations-save-share {
            width: 32.26vw;
            height: 10.4vw;
            border: 2px solid var(--color-secondary);
            border-radius: 1vw;
            box-sizing: border-box;
            background-color: transparent;
            font-family: var(--font-family-button);
            font-weight: 600;
            font-size: 4vw;
            color: var(--color-secondary);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 0;
        }

        .congratulations-more-photos {
            width: 33.6vw;
            height: 10.4vw;
            border: 2px solid var(--color-primary);
            box-sizing: border-box;
            border-radius: 1vw;
            background-color: var(--color-secondary);
            font-family: var(--font-family-button);
            font-weight: 600;
            font-size: 4vw;
            color: var(--color-primary);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 0;
        }

    }

}

@media (min-aspect-ratio: 9/15) {
    .congratulations-container {
        padding-top: 22vw;
    
        .souvenir-ticket-title {
            font-size: 4vw;
            line-height: 4.8vw;
        }
    
        .congratulations-subtitle {
            font-size: 4vw;
            line-height: 4.8vw;
            padding: 1vw 0 1vw 0;
        }
    
        .congratulations-photomontage-container {
            width: 30vw;
            height: 75vw;
            margin-top: 3vw;
    
            .congratulations-image {
                height: 55vw;
                top: 15vw;
            }
    
            .congratulations-template {
            }
        }
    
    
        .congratulations-buttons-container {
    
            .congratulations-save-share {
            }
    
            .congratulations-more-photos {
            }
    
        }
    
    }
}