.promotional-content-v2__promotional-content-container {
    width: 100vw;
    height: 79vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
    padding-top: 8vw;
    justify-content: flex-start;
    position: absolute;
    display: none;
    // background-color: red;

    .promotional-content-v2__promotional-text {
        font-family: var(--font-family-header);
        font-size: 7vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 6vw;
        letter-spacing: 2px;
        text-align: center;
        color: var(--color-tertiary);
        margin: 0;
        // text-transform: uppercase;

        .promotional-content-v2__new-england-title-bold {
            font-weight: 800;
            color: var(--color-primary);
        }
    }

    .promotional-content-v2__training-camp {
        font-family: var(--font-family-header);
        font-size: 4.26vw;
        font-weight: 500;
        color: var(--color-primary);
        text-align: center;
        margin: 2vw 0 0 0;
        letter-spacing: 2px;
    }

    .promotional-content-v2__line {
        width: 25.6vw;
        height: 2px;
        background-color: var(--color-primary);
        margin: 4.2vw 0;
        display: none;
    }

    .promotional-content-v2__camp-logo {
        width: 31.13vw;
        height: 19.2;
        display: none;
    }

    .promotional-content-v2__fantastic-photos {
        margin-top: 4vw;
        width: 60.26vw;
        display: none;
    }

    .promotional-content-v2__photo-container {
        margin: 4vw 0;
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;

        .promotional-content-v2__photo {
            width: 64.53vw;
        }
    }

    .promotional-content-v2__video-container {
        margin: 4vw 0;
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;


        .promotional-content-v2__video-content-container {
            width: 81.86vw; 
            height: 81.86vw;
            border-radius: 2.93vw;
            margin-top: 3vw;

            .promotional-content-v2__video {
                width: 81.86vw; 
                height: 81.86vw;
                border-radius: 2.93vw;
                // border: 0.25px solid #00325B;
                box-sizing: border-box;
            }
        }
        
        .promotional-content-v2__video-background-photo {
            display: none;
            width: 64vw;
            height: 64vw;
            justify-content: center;
            align-items: center;
            border: solid 1px #CCCCCC;
            background: #ffffff;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
            position: absolute;
            transform: rotate(5deg);
        }
    }

    .extended__video-container {
        width: 81.86vw; 
        height: 81.86vw;
        border-radius: 2.93vw;
        margin-top: 3vw;

        .extended__video {
            width: 81.86vw; 
            height: 81.86vw;
            border-radius: 2.93vw;
            border: 0.25px solid #00325B;
            box-sizing: border-box;
        }
    }


    .promotional-content-v2__fun-line {
        margin: 0;
        color: var(--color-secondary);
        text-transform: uppercase;
        font-family: Mazin;
        font-weight: 400;
        font-size: 3.8vw;
        letter-spacing: 2px;

        .promotional-content-v2__fun-line-fan {
            font-size: 4.8vw;
            font-weight: 800;
        }
    }

    .promotional-content-v2__strip-merged-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // background-color: cadetblue;
        padding-bottom: 42vw;


        .promotional-content-v2__strip-merged {
            width: 74vw;
            margin: 0vw 0vw;
        }
    }

    .promotional-content-v2__loading-smile {
        width: 65vw;
    }

    .promotional-content-v2__start-button {
        width: 35vw;
        height: 14vw;
        background-color: var(--color-primary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
    }

    .promotional-content-v2__start-button-text {
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 5.7vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }
}
@media (min-aspect-ratio: 9/15) {
    .promotional-content-v2__promotional-content-container {
        padding-top: 2vw;

        .promotional-content-v2__promotional-text {
            font-family: var(--font-family-primary);
            font-size: 9vw;
            font-weight: 400;
    
            .promotional-content-v2__new-england-title-bold {
                font-weight: 800;
                color: var(--color-secondary);
            }
        }
        
        .promotional-content-v2__training-camp {
            font-family: var(--font-family-primary);
            font-size: 3.6vw;
            margin: 0.7vw 0 0 0;
        }

        .promotional-content-v2__line {
            margin: 2.5vw 0;
        }
    
        .promotional-content-v2__camp-logo {
            width: 12vw;
        }
        
        .promotional-content-v2__video-container {
            margin: 2vw 0;
    
    
            .promotional-content-v2__video-content-container {
                width: 80vw;
                height: 80vw;
    
                .promotional-content-v2__video {
                    width: 80vw;
                    height: 80vw;
                }
            }
            
            .promotional-content-v2__video-background-photo {
                width: 50vw;
                height: 50vw;
            }
        }

        .promotional-content-v2__photo-container {
            margin: 3vw 0;
            width: 100vw;
            align-items: center;
            justify-content: center;
            display: flex;
    
            .promotional-content-v2__photo {
                width: 55vw;
            }
        }

        .promotional-content-v2__fantastic-photos {
            width: 62vw;
        }

        .promotional-content-v2__fun-line {
            margin: 0;
            color: var(--color-secondary);
            text-transform: uppercase;
            font-family: var(--font-family-primary);
            font-weight: 400;
            font-size: 3.2vw;
            letter-spacing: 2px;
    
            .promotional-content-v2__fun-line-fan {
                font-size: 4.3vw;
                font-weight: 800;
            }
        }

        .promotional-content-v2__strip-merged-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            // background-color: cadetblue;
            padding-bottom: 42vw;


            .promotional-content-v2__strip-merged {
                width: 86vw;
                margin: 0vw 0vw;
            }
        }

        .promotional-content-v2__loading-smile {
            width: 65vw;
        }

        .promotional-content-v2__start-button {
            width: 35vw;
            height: 14vw;
            background-color: var(--color-primary);
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin-top: 5vw;
            text-decoration: none;
        }

        .promotional-content-v2__start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 5.7vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: 1.2px;
        }
    }
}

@media (min-aspect-ratio: 10/9) {
    .promotional-content-v2__promotional-content-container {
        padding: 0.5vw 0 0 0;

        .promotional-content-v2__camp-logo {
            width: 15vw;
            height: auto;
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }

        .promotional-content-v2__promotional-text {
            font-size: 3vw;
            line-height: 1vw;
    
            .promotional-content-v2__new-england-title-bold {
            }
        }
    
        .promotional-content-v2__training-camp {
            font-size: 2.5vw;
            margin: 1vw 0 0 0;
        }

        .promotional-content-v2__photo-container {
            margin: 0vw 0;
            width: 37vw;
            height: auto;
            align-items: center;
            justify-content: center;
            display: flex;
    
            .promotional-content-v2__photo {
                width: 37vw;
            }
        }

        .promotional-content-v2__fantastic-photos {
            width: 25vw;

        }
    }
}