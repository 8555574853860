.app-container {

  .home-container,
  .session-url-container,
  .edit-photo-container,
  .final-photo-container,
  .loading-screen-container,
  .home-preview-container {
    padding-top: 15vw;

    .logo-header-container {
      padding-top: 0vw;

      .logo {
        display: flex;
        width: 22vw;
        margin-bottom: 0vw;
      }
    }

    .promotional-content-v2__promotional-content-container {
      display: flex;
      padding-top: 10vw;

      .promotional-content-v2__line {
        background-color: transparent;
      }

      .promotional-content-v2__promotional-text {
        text-transform: uppercase;
        font-family: var(--font-family-header);
        font-weight: 700;

        .promotional-content-v2__new-england-title-bold {
          text-transform: uppercase;
          font-family: var(--font-family-header);
          font-weight: 700;
        }
      }
    }

    .promotional-content-container {
      display: none;

      .promotional-group {
        .promotional-text {}
      }
    }

    .loading-bar-container {
      .loading-bar-content {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 69vh;

        .loading-logo-container {
          .loading-logo {
            width: 12.5vw;
            margin-top: 5vw;
          }
        }
      }
    }

    .loading-screen-content-container {
      .loading-screen-content {
        .loading-screen-logo-container {
          margin-bottom: -8.5vw;

          .loading-screen-logo {
            width: 12.5vw;
          }

          @keyframes loading-screen-logo-animation {
            0% {
              transform: translate(10vw, 0vw);
            }

            100% {
              transform: translate(45vw, 0vw);
            }
          }
        }
      }
    }

    .start-button {
      color: var(--color-primary);
      text-transform: none;
      border-radius: 27vw;
      width: 50vw;
      bottom: 13vw;
      height: 10vw !important;
      font-size: 4.2vw;
    }

    .promotional-content-v2__fantastic-photos {
      display: none;
    }

    .promotional-content-v2__promotional-text {
      letter-spacing: 0px;
      font-size: 7vw;
    }

    .botom-menu {
      margin: 3.3vw 0 0vw 0;
    }

    .save-container {
      width: 50vw;
      height: 10vw;
      border-radius: 26vw;
      bottom: 10vw;

      .loading-spinner {
        width: 10vw;
        height: 10vw;
      }

      .save {
        width: 40vw;
        height: 9vw;
        background-color: var(--color-tertiary);
        border-radius: 26vw;
        text-transform: unset;

        .text-save {
          font-size: 3.8vw;
        }
      }

      .save:active {
        background-color: var(--color-tertiary);
        color: #ffffff;
      }

      .contrast-save {
        background-color: var(--color-tertiary);

        .contrast-text-save {
          font-family: var(--font-family-primary);
          color: var(--color-primary);
        }
      }
    }

    .remove-watermark-button {
      width: 50vw;
      height: 13.8vw;
      background-color: var(--color-tertiary);
      border-radius: 26vw;
      color: var(--color-primary);
      font-family: var(--font-family-secondary);
      text-transform: unset;

      .remove-watermark-text {}
    }

    .remove-watermark-button:active {}

    .options-row {
      margin: 1vw 0;
      padding: 0vw 0vw;

      .menu-icon {
        padding-right: 7px;
        display: block;
      }

      .icon {
        fill: var(--color-secondary);
        stroke: var(--color-secondary);
      }

      .menu-icon-selected {
        fill: var(--color-tertiary);
        stroke: var(--color-tertiary);
      }
    }

    .cta-row {
      width: 90vw;

      .save-share-button {
        color: var(--color-primary);
        text-transform: capitalize;
        height: 8vw;
        font-size: 4.2vw;
        min-width: 35vw;

        .save-share-transparent {}

      }

      .save-share-button:active {}

      .edit-button {
        color: var(--color-tertiary);
      }
    }

    .photo-merged-container {

      .edit-button {
        color: var(--color-primary);
      }
    }
  }
}

@media (max-aspect-ratio: 9/16) and (min-resolution: 0.001dpcm) {
  .app-container {

    .home-container,
    .session-url-container,
    .edit-photo-container,
    .final-photo-container,
    .loading-screen-container,
    .home-preview-container {
      .logo-header-container {}

      .promotional-content-container {
        .promotional-group {}
      }

      .loading-bar-container {
        .loading-bar-content {
          display: flex;
          flex-direction: column;
          position: relative;
          top: 69vh;
        }
      }

      .options-row {
        padding: 0vw 0vw;

        .menu-icon {
          display: block;
        }

        .icon {
          fill: var(--color-secondary);
          stroke: var(--color-secondary);
        }

        .menu-icon-selected {
          fill: var(--color-tertiary);
          stroke: var(--color-tertiary);
        }
      }

      .photo-merged-container {

        .edit-button {
          color: var(--color-primary);
        }
      }

    }
  }
}

@media (max-aspect-ratio: 9/15) {
  .app-container {

    .home-container,
    .session-url-container,
    .edit-photo-container,
    .final-photo-container,
    .loading-screen-container,
    .home-preview-container {


      .photo-merged-container {

        .edit-button {
          color: var(--color-primary);
        }
      }

      .logo-header-container {
        .logo {
          display: flex;
          width: 27vw;
          margin-bottom: 0vw;
        }
      }

      .promotional-content-container {
        .promotional-group {}
      }

      .loading-bar-container {
        .loading-bar-content {
          display: flex;
          flex-direction: column;
          position: relative;
          top: 69vh;
        }
      }

      .options-row {
        padding: 0vw 10vw;

        .menu-icon {
          display: block;
        }

        .icon {
          fill: var(--color-secondary);
          stroke: var(--color-secondary);
        }

        .menu-icon-selected {
          fill: var(--color-tertiary);
          stroke: var(--color-tertiary);
        }
      }

      .save-container {
        width: 50vw;
        height: 10vw;
        border-radius: 26vw;
        bottom: 13vw;

        .loading-spinner {
          width: 10vw;
          height: 10vw;
        }

        .save {
          width: 50vw;
          height: 10vw;
          background-color: var(--color-tertiary);
          border-radius: 26vw;
          text-transform: unset;

          .text-save {
            font-size: 4.2vw;
          }
        }

        .save:active {
          background-color: var(--color-tertiary);
          color: #ffffff;
        }

        .contrast-save {
          background-color: var(--color-tertiary);

          .contrast-text-save {
            font-family: var(--font-family-primary);
            color: var(--color-primary);
          }
        }
      }

      .cta-row {
        width: 90vw;

        .save-share-button {
          color: var(--color-primary);
          text-transform: capitalize;
          height: 10vw;
          font-size: 4.2vw;
          min-width: 35vw;
  
          .save-share-transparent {}
  
        }
  
        .save-share-button:active {}
  
        .edit-button {
          color: var(--color-secondary);
        }
      }
    }
  }
}