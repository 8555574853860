.terms-and-conditions__agreement-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 2vw 0;

  .terms-and-conditions__input--container {
    width: 8.53vw;
    height: 8.53vw;

    input[type="checkbox"] {
      appearance: none;
      margin: 1.5vw 0 0 0;
      font: inherit;
      color: currentColor;
      width: 8.53vw;
      height: 8.53vw;
      border: 2px solid var(--color-tertiary);
      border-radius: 1.3vw;
      transform: translateY(-0.075em);

      display: flex;
      place-content: center;
    }

    input[type="checkbox"]:checked {
      background-color: var(--color-tertiary);
    }

    input[type="checkbox"]::before {
      content: "";
      width: 7vw;
      height: 7vw;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--color-white);
      background-color: var(--color-white);
      transform-origin: bottom left;
      clip-path: polygon(20% 51%, 41% 64%, 81% 21%, 85% 25%, 41% 72%, 17% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1) translateY(0.5vw);
    }
  }

  .terms-and-conditions__label {
    font-family: var(--font-family-primary);
    padding-left: 2vw;
    color: var(--color-primary);
    font-size: 4.27vw;
    line-height: 7.47vw;

  }

}