.user-photo__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 20vw;
  padding-bottom: 35vw;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-photo__title {
    font-family: var(--font-family-header);
    width: 85vw;
    text-align: left;
    font-size: 5.87vw;
    line-height: 6.67vw;
    color: var(--color-dark-grey);
    font-weight: 400;
    margin: 0;
    text-transform: none;
  }

  .user-photo__image--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .user-photo__image {
      width: 64vw;
    }
  }

  .user-photo__begin-button {
    position: absolute;
    bottom: 15vw;
    width: 85.33vw;
    height: 12.80vw;
    border-radius: 1.33vw;
    box-sizing: border-box;
    background-color: var(--color-tertiary);
    font-family: var(--font-family-button);
    font-weight: 600;
    font-size: 5.33vw;
    line-height: 7.2vw;
    color: var(--color-secondary);
    padding: 0;
    border: none;
  }
}