.retreat__promotional-content-container {
    width: 100vw;
    height: 79vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
    padding-top: 0vw;
    justify-content: flex-start;
    position: absolute;
    // background-color: red;

    .retreat__promotional-text {
        font-family: Poppins;
        font-size: 8.5vw;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: .9;
        letter-spacing: 2px;
        text-align: center;
        color: var(--color-tertiary);
        margin: 0;

        .retreat__new-england-title-bold {
            font-weight: 800;
            color: var(--color-secondary);
        }
    }

    .retreat__company-retreat {
        font-family: Poppins;
        font-size: 4.5vw;
        font-weight: 500;
        color: var(--color-secondary);
        text-align: center;
        margin: 2vw 0 0 0;
        letter-spacing: 5px;
        line-height: 1;
    }

    .retreat__line {
        width: 25.6vw;
        height: 2px;
        background-color: var(--color-tertiary);
        margin: 3.5vw 0 3.5vw 0;
    }

    .retreat__stone-houses {
        width: 19.2vw;
        height: 19.2vw;
    }

    .retreat__fantastic-photos {
        width: 72vw;
    }

    .retreat__photo-container {
        margin: 2.5vw 0 0vw 0;
        width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;

        .retreat__photo {
            width: 64.5vw;
        }
    }

    .retreat__fun-line {
        margin: 0;
        color: var(--color-secondary);
        text-transform: uppercase;
        font-family: Mazin;
        font-weight: 400;
        font-size: 3.8vw;
        letter-spacing: 2px;

        .retreat__fun-line-fan {
            font-size: 4.8vw;
            font-weight: 800;
        }
    }

    .retreat__strip-merged-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // background-color: cadetblue;
        padding-bottom: 42vw;


        .retreat__strip-merged {
            width: 74vw;
            margin: 0vw 0vw;
        }
    }

    .retreat__loading-smile {
        width: 65vw;
    }

    .retreat__start-button {
        width: 35vw;
        height: 14vw;
        background-color: var(--color-primary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
    }

    .retreat__start-button-text {
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 5.7vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }
}
@media (min-aspect-ratio: 9/15) {
    .retreat__promotional-content-container {
        // padding-top: 1vw;

        .retreat__promotional-text {
            font-size: 6.5vw;
    
            .retreat__new-england-title-bold {
                // font-weight: 800;
                // color: var(--color-secondary);
            }
        }
        
        .retreat__company-retreat {
            font-size: 3.5vw;
            margin: 1.5vw 0 0 0;
        }

        .retreat__line {
            margin: 2.5vw 0;
        }
    
        .retreat__camp-logo {
            // width: 12vw;
        }

        .retreat__photo-container {
            // margin: 3vw 0;
            // width: 100vw;
            // align-items: center;
            // justify-content: center;
            // display: flex;
    
            .retreat__photo {
                width: 50vw;
            }
        }

        .retreat__fantastic-photos {
            width: 62vw;
        }

        .retreat__fun-line {
            margin: 0;
            color: var(--color-secondary);
            text-transform: uppercase;
            // font-family: Mazin;
            font-weight: 400;
            font-size: 3.2vw;
            letter-spacing: 2px;
    
            .retreat__fun-line-fan {
                font-size: 4.3vw;
                font-weight: 800;
            }
        }

        .retreat__strip-merged-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            // background-color: cadetblue;
            padding-bottom: 42vw;


            .retreat__strip-merged {
                width: 86vw;
                margin: 0vw 0vw;
            }
        }

        .retreat__loading-smile {
            width: 65vw;
        }

        .retreat__start-button {
            width: 35vw;
            height: 14vw;
            background-color: var(--color-primary);
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin-top: 5vw;
            text-decoration: none;
        }

        .retreat__start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 5.7vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: 1.2px;
        }
    }
}