.select-your-card__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 20vw;
  padding-bottom: 35vw;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .select-your-card__title {
    font-family: var(--font-family-header);
    width: 85vw;
    text-align: left;
    font-size: 5.87vw;
    line-height: 6.67vw;
    color: var(--color-dark-grey);
    font-weight: 400;
    margin: 0;
    text-transform: none;
  }

  .select-your-card__carousel--main-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow: hidden;

    .select-your-card__carousel--container {}

  }

  .select-your-card__select-button--container {
    position: absolute;
    bottom: 15vw;
    width: 85.33vw;
    height: 12.80vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .select-your-card__select-button {
      width: 100%;
      height: 100%;
      border-radius: 1.33vw;
      box-sizing: border-box;
      background-color: var(--color-tertiary);
      font-family: var(--font-family-button);
      font-weight: 600;
      font-size: 5.33vw;
      line-height: 7.2vw;
      color: var(--color-secondary);
      padding: 0;
      border: none;
    }

    .loading-spinner {
      width: 13.3vw;
      height: 13.3vw;
      object-fit: cover;
    }
  }
}

.select-your-card__carousel--item {
  width: 56.53vw;
  height: 89.87vw;
  border-radius: 2.67vw;
  background-color: red;
}