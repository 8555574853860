.home-main-content-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5vw;
    
    .create-memory-container {
        width: 100vw;
        height: 156vw;
        background-size: 100vw 156vw;
    }

    .linear-background {
        width: 100vw;
        height: 156vw;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-image: linear-gradient(to bottom, rgba(2, 36, 66, 0) 73%, rgba(1, 34, 78, 0.49) 82%, var(--color-primary) 97%);

        .promo-header {
            width: 100vw;
            height: 25vw;
            background: var(--color-tertiary);
            margin-left: auto;
            margin-right: auto;
            margin-top: 2vw;
            margin-bottom: 2vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 13vw;
            box-sizing: border-box;
            align-items: center;

            .promo-text {
                font-size: 5.4vw;
                width: 80vw;
                position: absolute;
                font-weight: 500;
                color: var(--color-secondary);
            }
            
            .photo-booth {
                height: 25vw;
                object-fit: contain;
                margin-left: auto;
            }

        }

        .list-title {
            font-family: var(--font-family-primary);
            font-size: 5vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.48px;
            text-align: center;
            color: var(--color-secondary);
            padding: 0px;
            margin: 0px;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            font-family: var(--font-family-primary);
            font-size: 5vw;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.48px;
            line-height: 1.4;
            text-align: center;
            color: var(--color-secondary);
            padding: 0px;
            margin: 0px;
        }

    }

    .promotional-text {
        font-family: var(--font-family-primary);
        font-size: 6.5vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.48px;
        text-align: center;
        color: var(--color-secondary);
        padding: 0px;
        margin: 0px;
        padding-top: 5vw;
        padding-bottom: 1vw;
    }

    .photo-merged {
        width: 50vw;
        object-fit: cover;
    }

    .memory-1 {
        width: 63vw;
        margin-bottom: -43vw;
        margin-left: 1vw;
    }

    .memory-2 {
        width: 58vw;
        margin-left: 41vw;
        margin-bottom: -34vw;
    }

    .memory-3 {
        width: 66vw;
        margin-left: 9vw;
    }

    .start-button {
        width: 65vw;
        height: 14vw;
        background-color: var(--color-primary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
    }

    .start-button-text {
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 5.7vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }
}