.circles {
    background: red;

    circle {
      fill: #fff;
      transition: fill 0.3s ease;
      animation-name: spinner-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;

      &:nth-child(2) { animation-delay: 0.25s; }
      &:nth-child(3) { animation-delay: 0.5s; }
      &:nth-child(4) { animation-delay: 0.75s; }
      &:nth-child(5) { animation-delay: 1s; }
      &:nth-child(6) { animation-delay: 1.25s; }
      &:nth-child(7) { animation-delay: 1.5s; }
      &:nth-child(8) { animation-delay: 1.75s; }
    }
  }

  @keyframes spinner-animation {
    0% { fill: #fff; }
    25% { fill: #bbb; }
    50% { fill: #0aa0d9; }
    100% { transition: initial; }
  }


.display-block {
    display: block;
}

.display-none {
    display: none;
}