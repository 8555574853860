.select-photo-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-bottom: 15vw;
    align-items: center;
    background-color: var(--color-primary);
    padding-top: 31vw;
    min-height: 100vh;

    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.4);
        width: 100vw;
    }

    .action-row {
        width: 65vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4vw 0;
        margin-left: auto;
        margin-right: auto;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .download {
            width: 3.5vw;
        }
        .letter {
            width: 4vw;
        }
        .action-text {
            font-family: var(--font-family-primary);
            font-size: 3.75vw;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1;
            color: var(--color-secondary);
            padding: 0 2vw;
        }
    }

    .title-row {
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 12vw;
        
        .title-line {
            width: 15vw;
            height: 1px;
            background-color: var(--color-gray);
        }

        .select-text {
            font-family: var(--font-family-primary);
            font-size: 6vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: var(--color-tertiary);
        }
    }

    .photo-strip-container {
        width: 80vw;
        background-color: var(--color-secondary);
        padding: 8.5vw 6.5vw 6.5vw 6.5vw;
        box-sizing: border-box;

        .photo {
            width: 67vw;
            height: 67vw;
            overflow: hidden;
            // border: solid 1px var(--color-gray);
            box-sizing: border-box;
            margin: 2.5vw 0;
            object-fit: cover;
        }

        .sq-button {
            height: 11.7vw;
            position: absolute;
            fill: var(--color-primary);
            stroke: var(--color-primary);
        }

        .download-button-top-right {
            margin-top: 4vw;
            margin-left: -26vw;
            margin-bottom: 0vw;
        }

        .edit-pencil-top-right {
            margin-top: 4vw;
            margin-left: -13vw;
            margin-bottom: 0vw;
        }

        .download-button-top-left {
            margin-top: 4vw;
            margin-left: -66vw;
            margin-bottom: 0vw;
        }

        .edit-pencil-top-left {
            margin-top: 4vw;
            margin-left: -53vw;
            margin-bottom: 0vw;
        }
        
        .download-button-bottom-right {
            margin-top: 56.5vw;
            margin-left: -26vw;
            margin-bottom: 0vw;
        }

        .edit-pencil-bottom-right {
            margin-top: 56.5vw;
            margin-left: -13vw;
            margin-bottom: 0vw;
        }

        .download-button-bottom-left {
            margin-top: 56.5vw;
            margin-left: -66vw;
            margin-bottom: 0vw;
        }

        .edit-pencil-bottom-left {
            margin-top: 56.5vw;
            margin-left: -53vw;
            margin-bottom: 0vw;
        }

        .tap-text {
            font-family: var(--font-family-primary);
            font-size: 4vw;
            text-align: center;
            color: #000000;
            padding: 0px;
            margin: 0px;
            padding-top: 1vw;
            padding-bottom: 0vw;
        }

        .remove-watermark-button {
            width: 61vw;
            height: 13.3vw;
            background-color: var(--color-tertiary);
            position: absolute;
            margin-top: -33vw;
            margin-left: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;

            .remove-watermark-text {
                font-family: Poppins;
                font-size: 3.72vw;
                font-weight: 600;
                color: #ffffff;
                letter-spacing: 1px;
            }
        }
    }

    .upload-pick {
        margin-top: 1vw;
        background-size: 90vw 51vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3vw;
        box-sizing: border-box;
        // border: solid 1px var(--color-gray);
        .upload-cload-container {
            display: flex;
            width: 69vw;
            height: 69vw;
            background-color: #ffffff;
            justify-content: center;
            align-items: center;
            
            .upload-cload {
                width: 37vw;
                height: 33vw;
                stroke: var(--color-primary);
                fill: var(--color-primary);
            }
            
            .add-photo-text {
                position: absolute;
                font-family: var(--font-family-primary);
                font-weight: 600;
                font-size: 3.75vw;
                transform: translate(0, 16vw);
            }
        }
    }

    .sticky-header {
        position: fixed;
        top: 17vw;
        left: 0vw;
        background-color: var(--color-primary);
        width: 100vw;
        z-index: 100;
        padding-top: 1vw;

        .promotional-text {
            font-family: var(--font-family-primary);
            font-size: 6vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.48px;
            text-align: center;
            color: var(--color-secondary);
            padding: 0px;
            margin: 0px;
            padding-top: 1vw;
            padding-bottom: 0vw;
        }
    
        .subtitle {
            font-family: var(--font-family-primary);
            font-size: 4vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.48px;
            text-align: center;
            color: var(--color-secondary);
            padding: 0px;
            margin: 0px;
            padding-top: 1vw;
            padding-bottom: 1vw;
        }
    }

    .remove-watermark-button {
        width: 61.5vw;
        height: 13.5vw;
        background-color: var(--color-tertiary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
        position: fixed;
        bottom: 15vw;

        .remove-watermark-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 3.76vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            margin: 0px;
            padding: 0px;
        }
    }

}