:root {
    --color-primary: #04A1D9;
    --color-secondary: #ffffff;
    --color-tertiary: #04A1D9;
    --color-quaternary: #b0b7bc;
    --color-dark-gray: #292929;
    --color-gray: #949494;
    --color-green: #208a16;
    --color-pb-footer: #ffffff;
    --color-black: #000000;

    --font-family-primary: Poppins;
    --font-family-secondary: Poppins;
    --font-family-header: var(--font-family-primary);
    --font-family-body: var(--font-family-primary);
    --font-family-button: var(--font-family-primary);
}