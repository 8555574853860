
.session-url-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 12vw;
    padding-top: 21vw;
    align-items: center;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    
    .loading-spinner-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.4);
        width: 100vw;
    }

    .home-screen-img {
        width: 82vw;
        margin-top: 1vw;
    }

    .start-button {
        width: 50vw;
        height: 12vw;
        background-color: var(--color-secondary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 3px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
        position: fixed;
        bottom: 15vw;
    }

    .start-button-text {
        color: var(--color-primary);
        font-family: var(--font-family-primary);
        font-size: 4vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }

    .no-photos-found {
        font-family: var(--font-family-primary);
        color: var(--color-tertiary);
        font-size: 3.2vw;
        font-weight: 600;
        position: fixed;
        bottom: 12vw;
        text-align: center;
    }
}

.loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}