.no-matches-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url('/assets/images/nasdaq-background.png');
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    padding-top: 30vw;
    box-sizing: border-box;
    align-items: center;

    .no-matches-title {
        width: 60vw;
        font-family: var(--font-family-primary);
        text-align: center;
        font-size: 4.8vw;
        line-height: 5.8vw;
        color: var(--color-secondary);
        font-weight: 900;
        padding: 5.8vw 0 5.8vw 0;
        margin: 0;
    }

    .no-matches-text {
        width: 70vw;
        font-family: var(--font-family-primary);
        text-align: center;
        font-size: 4.2vw;
        line-height: 4.4vw;
        color: var(--color-secondary);
        font-weight: 500;
        padding: 3vw 0 3vw 0;
        margin: 0;
    }

    .no-matches-image {
        width: 55vw;
        height: 96vw;
        object-fit: cover;
        border: 0.25px solid var(--color-secondary);
        box-sizing: border-box;
        border-radius: 2.9vw;
    }

    .no-matches-hint {
        display: none;
    }

    .no-matches-buttons-container {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5vw 0 5vw;
        box-sizing: border-box;
        margin-top: auto;
        margin-bottom: auto;

        .no-matches-decline-button {
            width: 29.86vw;
            height: 8.8vw;
            border: 2px solid var(--color-secondary);
            border-radius: 26.6vw;
            box-sizing: border-box;
            background-color: transparent;
            font-family: var(--font-family-button);
            font-weight: 900;
            font-size: 3.2vw;
            color: var(--color-secondary);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .no-matches-try-again-button {
            width: 33.6vw;
            height: 10.4vw;
            box-sizing: border-box;
            border-radius: 1vw;
            background-color: var(--color-secondary);
            font-family: var(--font-family-button);
            font-weight: 600;
            font-size: 5vw;
            color: var(--color-primary);
            border: none;
        }

    }

}