.card__container {
  width: 56.53vw;
  height: 89.87vw;
  border-radius: 2.67vw;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
  transition: 0.9s;

  .card__image {
    width: 100%;
    height: 100%;
    border-radius: 2.67vw;
    overflow: hidden;
    border: none;
    transition: 0.9s;
  }
}

.card__non-active {
  transform: scale(0.9);
  transition: 0.9s;
}