.terms-and-conditions__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 20vw;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-light-gray);


  .terms-and-conditions__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .terms-and-conditions__title {
      font-family: var(--font-family-header);
      width: 85vw;
      text-align: left;
      font-size: 5.87vw;
      line-height: 6.67vw;
      color: var(--color-dark-grey);
      font-weight: 400;
      margin: 0;
      text-transform: none;
    }


  .terms-and-conditions__scroll-container-parent {
    margin-top: 2vw;
    flex: 1;
    padding-bottom: 32vw;
    background: var(--color-white);
    
    .terms-and-conditions__legal-agreement--container {
      width: 100%;
      flex: 1;
      background-color: var(--color-white);
      margin-top: 2vw;
      border-radius: 2.13vw 2.31vw 0 0;
      padding: 3.87vw 5.33vw 0 5.60vw;
      box-sizing: border-box;

      .terms-and-conditions__link {
        color: var(--color-light-blue);
        text-decoration: underline;
        font-weight: 600;
      }
    }
    
  }
  }
  
  .terms-and-conditions__read-and-agree--button {
    position: absolute;
    bottom: 15vw;
    width: 85.33vw;
    height: 12.80vw;
    border-radius: 1.33vw;
    box-sizing: border-box;
    background-color: var(--color-tertiary);
    font-family: var(--font-family-button);
    font-weight: 600;
    font-size: 5.33vw;
    line-height: 7.2vw;
    color: var(--color-secondary);
    padding: 0;
    border: none;
    text-transform: capitalize;
  }

  .terms-and-conditions__disabled {
    opacity: 0.5;
  }
}