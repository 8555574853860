.promotional-content-container {
    width: 100vw;
    height: 79vh;
    display: none;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
    justify-content: flex-start;
    position: absolute;
    // background-color: red;

    .promotional-text {
        font-family: var(--font-family-primary);
        font-size: 4.3vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1px;
        text-align: center;
        color: var(--color-secondary);
        padding: 0px;
        margin: 0px;
        padding-top: 1.2vh;
        padding-bottom: 0vw;
    }

    .fantastic-photos {
        width: 87vw;
        // background-color: red;
    }

    .photo-merged {
        width: 50vw;
        object-fit: cover;
    }

    .strip-merged-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // background-color: cadetblue;
        padding-bottom: 42vw;


        .strip-merged {
            width: 74vw;
            margin: 0vw 0vw;
        }
    }

    .loading-smile {
        width: 65vw;
    }

    .start-button {
        width: 35vw;
        height: 14vw;
        background-color: var(--color-primary);
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-top: 5vw;
        text-decoration: none;
    }

    .start-button-text {
        color: var(--color-secondary);
        font-family: var(--font-family-primary);
        font-size: 5.7vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 1.2px;
    }
}
@media (max-aspect-ratio: 9/15) {
    .promotional-content-container {

        .promotional-text {
        }

        .fantastic-photos {
        }

        .photo-merged {
        }

        .strip-merged-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            // background-color: cadetblue;
            padding-bottom: 42vw;


            .strip-merged {
                width: 86vw;
                margin: 0vw 0vw;
            }
        }

        .loading-smile {
            width: 65vw;
        }

        .start-button {
            width: 35vw;
            height: 14vw;
            background-color: var(--color-primary);
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin-top: 5vw;
            text-decoration: none;
        }

        .start-button-text {
            color: var(--color-secondary);
            font-family: var(--font-family-primary);
            font-size: 5.7vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: 1.2px;
        }
    }
}