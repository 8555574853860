.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(22, 22, 22, 1);
    // background-color: transparent !important;
    z-index: 202;
}

.transparent-overlay {
    background-color: transparent !important;
}

.modal-container {
    width: 90.6vw;
    min-height: 160vw;
    padding-bottom: 5vw;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: transparent;
    padding-top: 10vw;

    .close-modal-container {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        padding: 4vw 0vw;

        .close-modal-x {
            cursor: pointer;
        }
    }

    .modal-content {
        width: 90.6vw;
        min-height: 140vw;
        background: #00234B;
        padding: 5vw 5vw 5vw 5vw;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .menu-close {
            width: 6.4vw;
            height: 6.4vw;
            position: absolute;
            margin-left: -5vw;
        }


        .register-title {
            font-family: var(--font-family-terciary);
            text-align: center;
            font-weight: 700;
            color: #ffffff;
            font-size: 4.8vw;
            line-height: 6vw;
            padding-top: 2vw;
            margin: 0;
            letter-spacing: 1%;
        }

        .form-container {
            padding-top: 6vw;

            .input-container {
                width: 83vw;
                margin-top: 4.2vw;

                .input-label {
                    font-family: var(--font-family-secondary);
                    font-size: 3.2vw;
                    line-height: 3.2vw;
                    color: #ffffff;

                    .required {
                        color: #ffffff;
                    }
                }

                .input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                        
                    .input-firstname {
                        width: 77.3vw;
                        height: 11.2vw;
                        padding: 0 vw;
                        box-sizing: border-box;
                        font-size: 3.8vw;
                        letter-spacing: 1%;
                        font-family: var(--font-family-secondary);
                        color: #000000;
                        border-radius: 4px;
                        background-color: #ffffff;
                    }

                    .input-wrong-state {
                        border-color: var(--color-primary);
                        border-width: 2px;
                        border-style: solid;
                    }
                }

            }
        }

        .consent-container {
            display: flex;
            flex-direction: row;
            padding: 3vw 0;
            width: 79vw;

            .consent-check-container {
                padding-right: 1.5vw;

                .consent-check {
                    fill: #DCB400;
                    stroke: #DCB400;
                }
            }

            .agree-text {
                font-family: var(--font-family-secondary);
                font-size: 2.14vw;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                color: #ffffff;

                .agree-text-link {
                    font-size: 2.14vw;
                    text-decoration: underline;
                    font-weight: 700;
                    color: #DCB400;
                }
            }
        }

        .submit-button {
            width: 69.33vw;
            height: 12vw;
            background-color: rgba($color: #C81414, $alpha: 0.4);
            border-radius: 5.86vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            font-family: var(--font-family-secondary);
            font-size: 3.8vw;
            color: rgba($color: #ffffff, $alpha: 0.4);

            .submit-button-text {
                font-family: var(--font-family-secondary);
                font-size: 3.8vw;
                margin: 0;
                padding: 0;
                color: inherit;
            }
        }

        .submit-button-available {
            background-color: var(--color-primary-action);
            color: #ffffff;
        }

        // .submit-button-available:active {
        //     background-color: #000000;
        //     color: #ffffff;
        // }

        .submit-button-available:focus {
            background-color: #000000;
            color: #ffffff;
        }
        .submit-button-available:target {
            background-color: #000000;
            color: #ffffff;
        }

        .edit-pressed {
            background-color: #990000;
            color: #ffffff;
        }

    }

}

@media (max-aspect-ratio: 9/15) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background-color: rgba(22, 22, 22, 0);
        z-index: 202;
    }
    
    .modal-container {
        width: 90.6vw;
        min-height: 150vw;
        padding-bottom: 5vw;
        background: transparent;
        box-sizing: border-box;
        border: none;
        outline: transparent;
        padding-top: 25vw;
    
        .close-modal-container {
            width: 100%;
            justify-content: flex-end;
            display: flex;
            padding: 4vw 0vw;
    
            .close-modal-x {
                cursor: pointer;
            }
        }
    
        .modal-content {
            width: 90.6vw;
            min-height: 130vw;
            background: #00234B;
            padding: 5vw 5vw 5vw 5vw;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
    
            .menu-close {
                width: 6.4vw;
                height: 6.4vw;
                position: absolute;
                margin-left: -5vw;
            }
    
    
            .register-title {
                font-family: var(--font-family-terciary);
                text-align: center;
                font-weight: 700;
                color: #ffffff;
                font-size: 4.8vw;
                line-height: 6vw;
                padding-top: 2vw;
                margin: 0;
                letter-spacing: 1%;
            }
    
            .form-container {
                padding-top: 6vw;
    
                .input-container {
                    width: 83vw;
                    margin-top: 4.2vw;
    
                    .input-label {
                        font-family: var(--font-family-secondary);
                        font-size: 3.2vw;
                        line-height: 3.2vw;
                        color: #ffffff;
    
                        .required {
                            color: #ffffff;
                        }
                    }
    
                    .input-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                            
                        .input-firstname {
                            width: 77.3vw;
                            height: 11.2vw;
                            padding: 0 vw;
                            box-sizing: border-box;
                            font-size: 3.8vw;
                            letter-spacing: 1%;
                            font-family: var(--font-family-secondary);
                            color: #000000;
                            border-radius: 4px;
                            background-color: #ffffff;
                        }
    
                        .input-wrong-state {
                            border-color: var(--color-primary);
                            border-width: 2px;
                            border-style: solid;
                        }
                    }
    
                }
            }
    
            .consent-container {
                display: flex;
                flex-direction: row;
                padding: 3vw 0;
                width: 79vw;
    
                .consent-check-container {
                    padding-right: 1.5vw;
    
                    .consent-check {
                        fill: #DCB400;
                        stroke: #DCB400;
                    }
                }
    
                .agree-text {
                    font-family: var(--font-family-secondary);
                    font-size: 2.14vw;
                    margin: 0;
                    padding: 0;
                    text-transform: uppercase;
                    color: #ffffff;
    
                    .agree-text-link {
                        font-size: 2.14vw;
                        text-decoration: underline;
                        font-weight: 700;
                        color: #DCB400;
                    }
                }
            }
    
            .submit-button {
                width: 69.33vw;
                height: 12vw;
                background-color: rgba($color: #C81414, $alpha: 0.4);
                border-radius: 5.86vw;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: auto;
                margin-left: auto;
                margin-right: auto;
                font-family: var(--font-family-secondary);
                font-size: 3.8vw;
                color: rgba($color: #ffffff, $alpha: 0.4);
    
                .submit-button-text {
                    font-family: var(--font-family-secondary);
                    font-size: 3.8vw;
                    margin: 0;
                    padding: 0;
                    color: inherit;
                }
            }
    
            .submit-button-available {
                background-color: var(--color-primary-action);
                color: #ffffff;
            }
    
            // .submit-button-available:active {
            //     background-color: #000000;
            //     color: #ffffff;
            // }
    
            .submit-button-available:focus {
                background-color: #000000;
                color: #ffffff;
            }
            .submit-button-available:target {
                background-color: #000000;
                color: #ffffff;
            }
    
            .edit-pressed {
                background-color: #990000;
                color: #ffffff;
            }
    
        }
    
    }
}