.shipping-information-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url('/assets/images/nasdaq-background.png');
    background-size: cover;
    /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 26vw;
    box-sizing: border-box;
    align-items: center;

    .shipping-information-title {
        font-family: var(--font-family-primary);
        width: 100%;
        text-align: center;
        font-size: 4.8vw;
        line-height: 5.8vw;
        color: var(--color-secondary);
        font-weight: 900;
        padding: 5.8vw 0 5.8vw 0;
        margin: 0;
    }

    .shipping-information-content-container {
        width: 76.66vw;
        min-height: 140vw;
        padding: 5vw 0vw 5vw 0vw;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .menu-close {
            width: 6.4vw;
            height: 6.4vw;
            position: absolute;
            margin-left: -5vw;
        }


        .register-title {
            font-family: var(--font-family-primary);
            text-align: center;
            font-weight: 900;
            color: var(--color-secondary);
            font-size: 4.8vw;
            line-height: 5.8vw;
            padding-top: 0vw;
            margin: 0;
            letter-spacing: 1%;
            text-transform: uppercase;
        }

        .form-container {
            padding: 0vw 5vw 0vw 5vw;

            .input-container {
                width: 66.66vw;
                margin-top: 0vw;

                .input-label {
                    font-family: var(--font-family-secondary);
                    font-size: 2.6vw;
                    line-height: 2.6vw;
                    color: var(--color-secondary);
                    text-transform: uppercase;
                    margin: 0;

                    .required {
                        color: var(--color-secondary);
                    }
                }

                .input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .input-firstname {
                        width: 66.6vw;
                        height: 10.6vw;
                        padding: 0vw 2vw;
                        box-sizing: border-box;
                        font-size: 3.8vw;
                        letter-spacing: 1%;
                        font-family: var(--font-family-secondary);
                        color: #000000;
                        border-radius: 0px;
                        background-color: #ffffff;
                        border: 1px solid var(--color-secondary);
                    }

                    .input-wrong-state {
                        border-color: var(--color-primary);
                        border-width: 2px;
                        border-style: solid;
                    }


                    .input-state {
                        width: 24.2vw;
                    }

                    .input-zip-code {
                        width: 37.09vw;
                    }

                    .autocomplete-dropdown-container {
                        width: 66.6vw;
                        position: absolute;
                        padding: 0vw 2vw;
                        box-sizing: border-box;
                        font-size: 3.8vw;
                        letter-spacing: 1%;
                        font-family: var(--font-family-secondary);
                        color: #000000;
                        border-radius: 0px;
                        background-color: #ffffff;
                        opacity: 1;
                        // border: 4px solid var(--color-primary);
                        // border: none;

                        .suggestion-item {
                            padding: 1.5vw 0;
                            border-bottom: 1px var(--color-quaternary) solid;
                            background-color: #FFFFFF;
                            opacity: 1;
                        }
                    }

                }

            }

            .input-state-container {
                width: 24.2vw;
            }

            .input-zip-code-container {
                width: 37.09vw;
            }

            .input-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .required-advice {
                margin: 0.3vw 0;
                font-family: var(--font-family-primary);
                color: var(--color-secondary);
                font-size: 2.66vw;
            }
        }

        .consent-container {
            display: flex;
            flex-direction: row;
            padding: 3vw 0;
            width: 79vw;

            .consent-check-container {
                padding-right: 1.5vw;

                .consent-check {
                    fill: #DCB400;
                    stroke: #DCB400;
                }
            }

            .agree-text {
                font-family: var(--font-family-secondary);
                font-size: 2.14vw;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                color: #ffffff;

                .agree-text-link {
                    font-size: 2.14vw;
                    text-decoration: underline;
                    font-weight: 700;
                    color: #DCB400;
                }
            }
        }

        .submit-container {
            margin: 2.9vw 0;
            width: 26.13vw;
            height: 10.4vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            .loading-spinner {
                width: 10vw;
            }
            
            .submit-button {
                width: 26.13vw;
                height: 10.4vw;
                background-color: var(--color-tertiary);
                border-radius: 1vw;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: var(--font-family-secondary);
                font-size: 3.8vw;
                color: var(--color-primary);
                border: 2px solid #FFFFFF;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                opacity: 0.5;
    
                .submit-button-text {
                    font-family: var(--font-family-secondary);
                    font-size: 4vw;
                    margin: 0;
                    padding: 0;
                    color: inherit;
                    font-weight: 600;
                    text-transform: uppercase;
                }

            }

            .submit-button-available {
                background-color: var(--color-secondary);
                color: #ffffff;
                opacity: 1;
            }

            .error-advice {
                font-family: var(--font-family-primary);
                text-align: center;
                font-weight: 700;
                color: var(--color-secondary);
                font-size: 3.5vw;
                line-height: 5vw;
                padding-top: 0vw;
                margin: 0;
                text-transform: uppercase; 
            }
        }



        // .submit-button-available:active {
        //     background-color: #000000;
        //     color: #ffffff;
        // }

        .submit-button-available:focus {
            background-color: #000000;
            color: #ffffff;
        }

        .submit-button-available:target {
            background-color: #000000;
            color: #ffffff;
        }

        .edit-pressed {
            background-color: #990000;
            color: #ffffff;
        }

        .terms-advice {
            width: 60vw;
            margin: 0.3vw 0;
            font-family: var(--font-family-primary);
            color: var(--color-secondary);
            font-size: 2.66vw;
            line-height: 3.46vw;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

    }

}

@media (min-aspect-ratio: 9/15) {
    .shipping-information-container {
        padding-top: 16vw;
    
        .shipping-information-title {
        }
    
        .shipping-information-content-container {
            align-items: center;
    
            .menu-close {
            }
    
    
            .register-title {
                width: 70vw;
                font-size: 4vw;
                line-height: 4.8vw;
            }
    
            .form-container {
    
                .input-container {
    
                    .input-label {
                        font-size: 2.6vw;
    
                        .required {
                        }
                    }
    
                    .input-row {
    
                        .input-firstname {
                            height: 8vw;
                            font-size: 3.5vw;
                        }
    
                        .input-wrong-state {
                        }
    
    
                        .input-state {
                        }
    
                        .input-zip-code {
                        }
    
                        .autocomplete-dropdown-container {
                            font-size: 3.5vw;
    
                            .suggestion-item {
                            }
                        }
    
                    }
    
                }
    
                .input-state-container {
                }
    
                .input-zip-code-container {
                }
    
                .input-row {
                }
    
                .required-advice {
                }
            }
    
            .consent-container {
    
                .consent-check-container {
    
                    .consent-check {
                    }
                }
    
                .agree-text {
    
                    .agree-text-link {
                    }
                }
            }
    
            .submit-button {
    
                .submit-button-text {
                }
            }
    
            .submit-button-available {
            }
        
            .submit-button-available:focus {
            }
    
            .submit-button-available:target {
            }
    
            .edit-pressed {
            }
    
            .terms-advice {
            }
    
        }
    
    }
}